import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";

const DropDown = ({ categoriesData, setDropDown }) => {
  const navigate = useNavigate();

  const submitHandle = (i) => {
    console.log(i._id)
    console.log(i.cat.title)
    navigate(`/products?category=${i.cat.title}&sousCategory=${i._id}`);
  };
  if (setDropDown === "Format 20 kg") {
    return (
      <div className="pb-4 w-[270px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src="chat.jpg" alt="Logo" width={"150px"} />
      </div>
    );
  } else {
    return (
      <div className="pb-4 w-[270px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm text-black1 800px:text-[#fff]">
        {categoriesData &&
          categoriesData.map((i, index) => {
            if (i.cat.title === setDropDown) { 
              return (
                <li key={index}>
                  <div className={`${styles.normalFlex}`} style={{ marginLeft: "10%" }}>
                    <h5 className="m-3p cursor-pointer select-none" style={{ color: "black" }} onClick={() => submitHandle(i)}>
                      {i.title}
                    </h5>
                  </div>
                </li>
              );
            }
            return null; 
          })}
      </div>
    );
  }
};

export default DropDown;
