import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createProduct } from "../../redux/actions/product";
import { useNavigate } from "react-router-dom";
const ProductForm = (sousCategories) => {
  
const dispatch = useDispatch();
  const listSousCategories=sousCategories.sousCategories;
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    nameFR: '',
    nameEN: '',
    descriptionFR: '',
    descriptionEN: '',
    composants:'',
    composantsEN:'',
    additifsFR:'',
    additifsEN:'',
    price: '',
    stock: 0,
    MinQte: 0,
    displayImage: File,
    selectedSousCategory: {},
  });
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setFormData({ ...formData, [name]: files });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSousCategoryChange = (e) => {
    const selectedSousCategory = e.target.value;
    setFormData({ ...formData, selectedSousCategory: selectedSousCategory });
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = async (e) => {
    const formDataWithImages = new FormData();
    const sousCategoryObject = JSON.parse(formData.selectedSousCategory);
  // Append form data
  formDataWithImages.append('nameFR', formData.nameFR);
  formDataWithImages.append('nameEN', formData.nameEN);
  formDataWithImages.append('descriptionFR', formData.descriptionFR);
  formDataWithImages.append('descriptionEN', formData.descriptionEN);
  formDataWithImages.append('composants', formData.composants);
  formDataWithImages.append('composantsEN', formData.composantsEN);
  formDataWithImages.append('additifsFR', formData.additifsFR);
  formDataWithImages.append('additifsEN', formData.additifsEN);
  formDataWithImages.append('price', formData.price);
  formDataWithImages.append('stock', formData.stock);
  formDataWithImages.append('minQte', formData.MinQte);
  formDataWithImages.append('sousCategoryId', sousCategoryObject._id);
  formDataWithImages.append('cat', sousCategoryObject.cat.title);

  // Append display image
  formDataWithImages.append('displayImage', selectedFile);
  try {
    // Dispatch the action and wait for it to complete
    await dispatch(createProduct(formDataWithImages));

    // After the action is completed, navigate to "/admin-products"
    navigate("/admin-products");
  } catch (error) {
    // Handle any errors that occur during the dispatch or navigation
    console.error("Error:", error);
  }
  };

  return (
    <form className="w-full mx-8 pt-1 mt-10">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Name (FR):</label>
        <input
          type="text"
          name="nameFR"
          value={formData.nameFR}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Name (EN):</label>
        <input
          type="text"
          name="nameEN"
          value={formData.nameEN}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Composition (FR):</label>
        <textarea
          name="descriptionFR"
          value={formData.descriptionFR}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Composition (EN):</label>
        <textarea
          name="descriptionEN"
          value={formData.descriptionEN}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Composants analytiques (FR):</label>
        <textarea
          name="composants"
          value={formData.composants}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Composants analytiques (EN):</label>
        <textarea
          name="composantsEN"
          value={formData.composantsEN}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Additifs nutritionnels  (FR):</label>
        <textarea
          name="additifsFR"
          value={formData.additifsFR}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Additifs nutritionnels (EN):</label>
        <textarea
          name="additifsEN"
          value={formData.additifsEN}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Price:</label>
        <input
          type="text"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Category : </label>
        <select
          name="selectedSousCategory"
          value={formData.selectedSousCategory}
          onChange={handleSousCategoryChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        >
          <option value="">Select a Category</option>
          {listSousCategories && listSousCategories.length > 0
            ? listSousCategories.map((sousCategory) => (
                <option key={sousCategory._id} value={JSON.stringify(sousCategory)}>
                  {sousCategory.cat.title} : {sousCategory.title}
                </option>
              ))
            : null}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Stock:</label>
        <input
          type="number"
          name="stock"
          value={formData.stock}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Min quantity to sell:</label>
        <input
          type="number"
          name="MinQte"
          value={formData.MinQte}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4 flex flex-wrap">
        <div className="w-full sm:w-1/2 mb-2 sm:mb-0 pr-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">Image:</label>
          <input
            type="file"
            name="displayImage"
            onChange={handleFileChange}
            className="w-full p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
      </div>
      <button
        type="button" onClick={handleSubmit} 
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Add Product
      </button>
      
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </form>
    
  );
};

export default ProductForm;
