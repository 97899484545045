import React, { useState } from 'react';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { useTranslation } from 'react-i18next';
function FAQPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSubTab, setSelectedSubTab] = useState(-1);
  const { t } = useTranslation(); 
  const tabs = ['MENTIONS LEGALES', 'ACCES ET NAVIGATION', 'CONTENU ET PROTECTION DU SITE INTERNET','RESPONSABILITES', 'POLITIQUE DE CONFIDENTIALITE', 'TRACEURS','DROIT APPLICABLE – REGLEMENT DES LITIGES'];
  const subTabs = [[], [], [],[], ['DONNEES PERSONNELLES COLLECTEES ET TRAITEES','HEBERGEUR / RESPONSABLE DU TRAITEMENT','DROIT DES UTILISATEURS','ENGAGEMENTS DE LA FYRME S.A.S'], [],[],];
  const tabContent = [t('FAQPage.tab1'),t('FAQPage.tab2'),t('FAQPage.tab3'),t('FAQPage.tab4'),
    [t('FAQPage.tab5'),t('FAQPage.tab6'),t('FAQPage.tab7'),t('FAQPage.tab8')],
    t('FAQPage.tab9'),t('FAQPage.tab10')
  ];

  const selectedTabContent = selectedSubTab === -1 ? tabContent[selectedTab] : tabContent[selectedTab][selectedSubTab];
  //const paragraphs = selectedTabContent.split('<br/><br/>');
  return (
    <div>
      <Header activeHeading={8} />
      <br />
      <br />
      <div className="faq-container">
        <h1>FAQ</h1>

        <div className="tab-container">
  {tabs.map((tab, index) => (
    <div
      key={index}
      className={`tab ${index === selectedTab ? 'active' : ''}`}
      onClick={() => {
        setSelectedTab(index);
        setSelectedSubTab(-1); // Reset selectedSubTab when clicking a tab
      }}
    >
      {t(`FAQPage.tabs.${index}`)}
    </div>
  ))}
</div>

        {subTabs[selectedTab] && subTabs[selectedTab].length > 0 && (
          <div className="subtab-container">
            {subTabs[selectedTab].map((subtab, index) => (
              <div
                key={index}
                className={`subtab ${index === selectedSubTab ? 'active' : ''}`}
                onClick={() => setSelectedSubTab(index)}
              >
                 {t(`FAQPage.subTabs.${selectedTab}.${index}`)}
              </div>
            ))}
          </div>
        )}
        <div class="container-text">
  <div class="centered-div">
    <p class="right-align-text"><div  dangerouslySetInnerHTML={{ __html: selectedTabContent }} /></p>
  </div>
</div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default FAQPage;
