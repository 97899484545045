import React from 'react';
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AllProducts from "../components/Admin/AllProducts";
import {  useNavigate } from "react-router-dom";
const AdminDashboardProducts = () => {
  const navigate = useNavigate();
  const goToAdd = () => {
    navigate('/admin-add-product');
  };
  return (
    <div>
      <AdminHeader />
      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[80px] 800px:w-[330px]">
            <AdminSideBar active={5} />
          </div>
          <div className="flex-1" style={{marginTop: '5%'}}>
            <AllProducts />
          </div>
          <div style={{ width: '9rem',marginTop: '4%' }}>
            <button onClick={goToAdd} style={{ color:"white",backgroundColor:'#105FAE', borderRadius:'10%', width:'80%' }}>Add Product</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardProducts;