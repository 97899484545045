import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductDetails from "../components/Products/ProductDetails";
import { useSelector } from "react-redux";
const ProductDetailsPage = () => {
  const { id } = useParams();
  const {allProducts,isLoading} = useSelector((state) => state.products);
  return (
    <div>
      <Header />
      <ProductDetails data={allProducts && allProducts.find((item) => item._id === id)} />
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
