// navigation Data
export const navItems = [
  {
    title: "Accueil",
    url: "/",
  },
  {
    title: "À propos",
    url: "/about",
  },
  {
    title: "Chien",
  },
  {
    title: "Chat",
  },
  {
    title: "Format 20 kg",
  },
  {
    title: "Avantages pro",
    url: "/products",
  },
  {
    title: "Contact",
    url: "/contact",
  },
  {
    title: "FAQ",
    url: "/faq",
  }
];
export const navItemsEn = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Dog",
  },
  {
    title: "Cat",
  },
  {
    title: "Format 20 kg",
  },
  {
    title: "Pro benefits",
    url: "/products",
  },
  {
    title: "Contact",
    url: "/contact",
  },
  {
    title: "FAQ",
    url: "/faq",
  }
];
  // branding data
  export const brandingData = [
    {
      id: 1,
      title:"croquettes chiens et chats sans céréales pour professionnels",
      icon: "/respect.svg",
    },
    {
      id: 2,
      title:"croquettes chiens et chats sans céréales pour professionnels",
      icon: "/commande.svg",
    },
    {
      id: 3,
      title:"croquettes chiens et chats sans céréales pour professionnels",
      icon: "/paiment.svg",
    },
    {
      id: 5,
      
      title:"croquettes chiens et chats sans céréales pour professionnels",
      icon: "/tarif.svg",
    },
    {
      id: 5,
      
      title:"croquettes chiens et chats sans céréales pour professionnels",
      icon: "/format.svg",
    },
  ];
  
  // categories data
  export const categoriesData = [
    {
      id: 1,
      title: "Chat",
      subTitle: "",
      image_Url:
        "/chat.jpg",
    },
    {
      id: 2,
      title: "Chien",
      subTitle: "",
      image_Url:
        "/chien.jpg",
    },
  ];
  export const sousCategoriesData = [
    {
      id: 1,
      title: "Croquettes",
      subTitle: "",
      cat: 1
    },{
      id: 2,
      title: "Pâtées",
      cat: 1
    },{
      id: 3,
      title: "Friandises",
      cat: 1
    },{
      id: 4,
      title: "Compléments",
      cat: 1
    },{
      id: 5,
      title: "Échantillons",
      cat: 1
    },{
      id: 6,
      title: "Promotions",
      cat: 1
    },
    {
      id: 7,
      title: "Croquettes",
      cat: 2
    },
    {
      id: 8,
      title: "Pâtées",
      cat: 2
    },
    {
      id: 9,
      title: "Litière & soins",
      cat: 2
    },
    {
      id: 10,
      title: "Friandises & compléments",
      cat: 2
    },
    {
      id: 11,
      title: "Échantillons",
      cat: 2
    },
    {
      id: 12,
      title: "Promotions",
      cat: 2
    },
  ];
  
  // product Data
  export const productData = [
    {
      "_id": {
        "$oid": "651566acbacba151d54e1fc5"
      },
      "minQte": {
        "$numberInt": "10"
      },
      "descriptionEN": " Protéines de volaille déshydratées 45%, riz, maïs, graisse de volaille \r\n8% (source d’acide gras oméga6), pré mélange de blé, pulpe de betterave, huile \r\nde poisson 2,5% (source d’acide gras oméga 3-EPA- DHA), foie de volaille 2,5%, \r\nFOS fructo-oligosaccharide de betterave, phosphate monocalcique, levures, \r\ncarbonate de calcium, sulfate de calcium, extrait de levures, extrait de yucca, \r\nchlorure de sodium.",
      "descriptionFR": " Protéines de volaille déshydratées 45%, riz, maïs, graisse de volaille \r\n8% (source d’acide gras oméga6), pré mélange de blé, pulpe de betterave, huile \r\nde poisson 2,5% (source d’acide gras oméga 3-EPA- DHA), foie de volaille 2,5%, \r\nFOS fructo-oligosaccharide de betterave, phosphate monocalcique, levures, \r\ncarbonate de calcium, sulfate de calcium, extrait de levures, extrait de yucca, \r\nchlorure de sodium.",
      "composants": " énergie métabolisable 5340,11 kcal/kg, protéines \r\nbrutes 33%, matières grasses brutes 19,44%, fibres brutes 0,21%, cendres brutes \r\n11,25%, humidité 5,78%, calcium 3,68%, phosphore 1,85%, magnésium 0,12%, \r\nmanganèse 44ppm, fer 228ppm, zinc 142ppm, cuivre 22,24ppm.",
      "composantsEN": " énergie métabolisable 5340,11 kcal/kg, protéines \r\nbrutes 33%, matières grasses brutes 19,44%, fibres brutes 0,21%, cendres brutes \r\n11,25%, humidité 5,78%, calcium 3,68%, phosphore 1,85%, magnésium 0,12%, \r\nmanganèse 44ppm, fer 228ppm, zinc 142ppm, cuivre 22,24ppm.",
      "additifsFR": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine 2000mg, Vitamine B5-Panthothénate \r\n1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-Biotine 12mg, Vitamine B9-\r\nacide folique 24mg, Vitamine B12-Cyanocobalamine1,2mg, Vitamine C\u0002Phosphate d’ascorbyle de Ca-Na ; chlorure de choline 52000mg, oligo-éléments : \r\nFer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc 5360 mg/kg, \r\nsélénium 4 mg/kg, Iode 120mg/kg, substances aromatiques, Additifs \r\ntechnologiques : Acide citrique E330 2500mg.",
      "additifsEN": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine 2000mg, Vitamine B5-Panthothénate \r\n1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-Biotine 12mg, Vitamine B9-\r\nacide folique 24mg, Vitamine B12-Cyanocobalamine1,2mg, Vitamine C\u0002Phosphate d’ascorbyle de Ca-Na ; chlorure de choline 52000mg, oligo-éléments : \r\nFer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc 5360 mg/kg, \r\nsélénium 4 mg/kg, Iode 120mg/kg, substances aromatiques, Additifs \r\ntechnologiques : Acide citrique E330 2500mg.",
      "displayImage": "/0.jpg",
      "images": [],
      "nameEN": "CROQUETTES PREMIUM POUR CHIEN ADULTE 2 kg (VOLAILLES)",
      "nameFR": "CROQUETTES PREMIUM POUR CHIEN ADULTE 2 kg (VOLAILLES)",
      "price": {
        "$numberInt": "50"
      },
      "stock": {
        "$numberInt": "5100"
      },
      "createdAt": {
        "$date": {
          "$numberLong": "1695901223175"
        }
      },
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "651567b8bacba151d54e1fd6"
      },
      "minQte": {
        "$numberInt": "100"
      },
      "descriptionEN": "Protéines de volaille déshydratées 50 %, riz, pré mélange de blé, \r\nmaïs, graisse de volaille 6%( source d’acide gras oméga6), pulpe de betterave, \r\nhuile de poisson 3% (source d’acide gras oméga 3-EPA- DHA), foie de volaille \r\n2,5%, FOS fructo-oligosaccharide de betterave, phosphate monocalcique, \r\nlevures, carbonate de calcium, sulfate de calcium, extrait de levures, extrait de \r\nyucca, chlorure de sodium.\r\n",
      "descriptionFR": "Protéines de volaille déshydratées 50 %, riz, pré mélange de blé, \r\nmaïs, graisse de volaille 6%( source d’acide gras oméga6), pulpe de betterave, \r\nhuile de poisson 3% (source d’acide gras oméga 3-EPA- DHA), foie de volaille \r\n2,5%, FOS fructo-oligosaccharide de betterave, phosphate monocalcique, \r\nlevures, carbonate de calcium, sulfate de calcium, extrait de levures, extrait de \r\nyucca, chlorure de sodium.\r\n",
      "composants": "énergie métabolisable 5452,82 kcal/kg, protéines \r\nbrutes 34%, matières grasses brutes 20,82%, fibres brutes 0,31%, cendres brutes \r\n11,23%, humidité 6,54%, calcium 3,78%, phosphore 1,70%, magnésium 0,11%, \r\nmanganèse 55,4ppm, fer 225,2ppm, zinc 312,5ppm, cuivre 29,9ppm",
      "composantsEN": "énergie métabolisable 5452,82 kcal/kg, protéines \r\nbrutes 34%, matières grasses brutes 20,82%, fibres brutes 0,31%, cendres brutes \r\n11,23%, humidité 6,54%, calcium 3,78%, phosphore 1,70%, magnésium 0,11%, \r\nmanganèse 55,4ppm, fer 225,2ppm, zinc 312,5ppm, cuivre 29,9ppm",
      "additifsFR": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine 520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique 24mg, Vitamine B12-\r\nCyanocobalamine1.2mg, Vitamine C-Phosphate d’ascorbyle de Ca-Na ; chlorure \r\nde choline 52000mg, oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg/kg, \r\nsubstances aromatiques, Additifs technologiques : Acide citrique E330 2500mg",
      "additifsEN": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine 520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique 24mg, Vitamine B12-\r\nCyanocobalamine1.2mg, Vitamine C-Phosphate d’ascorbyle de Ca-Na ; chlorure \r\nde choline 52000mg, oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg/kg, \r\nsubstances aromatiques, Additifs technologiques : Acide citrique E330 2500mg",
      "displayImage": "/2.jpg",
      "images": [],
      "nameEN": "CROQUETTES PREMIUM POUR CHIOT 2 kg (VOLAILLES)",
      "nameFR": "CROQUETTES PREMIUM POUR CHIOT 2 kg (VOLAILLES)",
      "price": {
        "$numberInt": "80"
      },
      "stock": {
        "$numberInt": "6251"
      },
      "createdAt": {
        "$date": {
          "$numberLong": "1695901223175"
        }
      },
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65156887bacba151d54e1ff4"
      },
      "minQte": {
        "$numberInt": "60"
      },
      "descriptionEN": " Protéines de volaille déshydratées 52%, riz, maïs, pré mélange de \r\nblé, pulpe de betterave, huile de poisson 3,4% (source d’acide gras oméga 3-EPA\u0002DHA), farine de poisson 3,4% (source d’acide gras oméga 3), foie de volaille 2,5%, \r\ngraisse de volaille 2,2% (source d’acide gras oméga 6), FOS fructo-oligosaccharide \r\nde betterave, phosphate mono calcique, levures, carbonate de calcium, sulfate de \r\ncalcium, extrait de levures, extrait de yucca, chlorure de sodium.",
      "descriptionFR": " Protéines de volaille déshydratées 52%, riz, maïs, pré mélange de \r\nblé, pulpe de betterave, huile de poisson 3,4% (source d’acide gras oméga 3-EPA\u0002DHA), farine de poisson 3,4% (source d’acide gras oméga 3), foie de volaille 2,5%, \r\ngraisse de volaille 2,2% (source d’acide gras oméga 6), FOS fructo-oligosaccharide \r\nde betterave, phosphate mono calcique, levures, carbonate de calcium, sulfate de \r\ncalcium, extrait de levures, extrait de yucca, chlorure de sodium.",
      "composants": " énergie métabolisable 5451,8 kcal/kg, protéines \r\nbrutes 33%, Matières grasses brutes 19,44%, fibres brutes 0,21%, cendres brutes \r\n11,25%, humidité 5,78%, calcium 3,68%, phosphore 1,85%, magnésium 0,12%, \r\nmanganèse 44ppm, fer 228ppm, zinc 142ppm, cuivre 22,24ppm",
      "composantsEN": " énergie métabolisable 5451,8 kcal/kg, protéines \r\nbrutes 33%, Matières grasses brutes 19,44%, fibres brutes 0,21%, cendres brutes \r\n11,25%, humidité 5,78%, calcium 3,68%, phosphore 1,85%, magnésium 0,12%, \r\nmanganèse 44ppm, fer 228ppm, zinc 142ppm, cuivre 22,24ppm",
      "additifsFR": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000 mg/kg, Vitamine B1-Thiamine 160mg, Vitamine B2-\r\nRiboflavine 520 mg, Vitamine PP-Niacine 2000 mg, Vitamine B5-Panthothénate \r\n1200 mg, Vitamine B6-pyridoxine 240 mg, Vitamine B8-Biotine 12 mg, Vitamine \r\nB9-acide folique 24 mg, Vitamine B12-Cyanocobalamine 1,2 mg, Vitamine C\u0002Phosphate d’ascorbyle de Ca-Na ; chlorure de choline 52000 mg, oligo-éléments : \r\nFer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc 5360 mg/kg, \r\nSélénium 4 mg/kg, Iode 120mg, substances aromatiques, Additifs technologiques \r\n: Acide Citrique E330 2500mg",
      "additifsEN": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000 mg/kg, Vitamine B1-Thiamine 160mg, Vitamine B2-\r\nRiboflavine 520 mg, Vitamine PP-Niacine 2000 mg, Vitamine B5-Panthothénate \r\n1200 mg, Vitamine B6-pyridoxine 240 mg, Vitamine B8-Biotine 12 mg, Vitamine \r\nB9-acide folique 24 mg, Vitamine B12-Cyanocobalamine 1,2 mg, Vitamine C\u0002Phosphate d’ascorbyle de Ca-Na ; chlorure de choline 52000 mg, oligo-éléments : \r\nFer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc 5360 mg/kg, \r\nSélénium 4 mg/kg, Iode 120mg, substances aromatiques, Additifs technologiques \r\n: Acide Citrique E330 2500mg",
      "displayImage": "/4.jpg",
      "images": [],
      "nameEN": "CROQUETTES PREMIUM POUR CHAT ADULTE 2 kg (VOLAILLES)",
      "nameFR": "CROQUETTES PREMIUM POUR CHAT ADULTE 2 kg (VOLAILLES)",
      "price": {
        "$numberInt": "50"
      },
      "stock": {
        "$numberInt": "5060"
      },
      "createdAt": {
        "$date": {
          "$numberLong": "1695901223175"
        }
      },
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65156ac5bacba151d54e2b75"
      },
      "minQte": {
        "$numberInt": "50"
      },
      "descriptionEN": "Protéines de viande de volaille déshydratée 55%, riz, maïs, pois \r\nchiche, amidon de maïs, grains de lin, graisse de volaille 2,5%( source d’acide gras \r\noméga6), huile de poisson 1% (source d’acide gras oméga 3-EPA-DHA), foie de \r\nvolaille 2% (avec levure et chlorure de sodium), sel.",
      "descriptionFR": "Protéines de viande de volaille déshydratée 55%, riz, maïs, pois \r\nchiche, amidon de maïs, grains de lin, graisse de volaille 2,5%( source d’acide gras \r\noméga6), huile de poisson 1% (source d’acide gras oméga 3-EPA-DHA), foie de \r\nvolaille 2% (avec levure et chlorure de sodium), sel.",
      "composants": "énergie métabolisable 3460 kcal/kg, protéines brutes \r\n37%, matières grasses brutes 12,6%, fibres brutes 3,06%, cendres brutes 10,5%, \r\nhumidité 6%, calcium 3,7%, phosphore 1,4%, magnésium 0,15%, fer 35 mg/Kg, \r\nzinc 134 mg/Kg, cuivre 12 mg/Kg, manganèse 41mg/Kg, vitamine A 23712 UI/KG, \r\nvitamine D 716 UI/KG.",
      "composantsEN": "énergie métabolisable 3460 kcal/kg, protéines brutes \r\n37%, matières grasses brutes 12,6%, fibres brutes 3,06%, cendres brutes 10,5%, \r\nhumidité 6%, calcium 3,7%, phosphore 1,4%, magnésium 0,15%, fer 35 mg/Kg, \r\nzinc 134 mg/Kg, cuivre 12 mg/Kg, manganèse 41mg/Kg, vitamine A 23712 UI/KG, \r\nvitamine D 716 UI/KG.",
      "additifsFR": "énergie métabolisable 3460 kcal/kg, protéines brutes \r\n37%, matières grasses brutes 12,6%, fibres brutes 3,06%, cendres brutes 10,5%, \r\nhumidité 6%, calcium 3,7%, phosphore 1,4%, magnésium 0,15%, fer 35 mg/Kg, \r\nzinc 134 mg/Kg, cuivre 12 mg/Kg, manganèse 41mg/Kg, vitamine A 23712 UI/KG, \r\nvitamine D 716 UI/KG.",
      "additifsEN": "énergie métabolisable 3460 kcal/kg, protéines brutes \r\n37%, matières grasses brutes 12,6%, fibres brutes 3,06%, cendres brutes 10,5%, \r\nhumidité 6%, calcium 3,7%, phosphore 1,4%, magnésium 0,15%, fer 35 mg/Kg, \r\nzinc 134 mg/Kg, cuivre 12 mg/Kg, manganèse 41mg/Kg, vitamine A 23712 UI/KG, \r\nvitamine D 716 UI/KG.",
      "displayImage": "/6.jpg",
      "images": [],
      "nameEN": "CROQUETTES PREMIUM POUR CHATS STERILISE 2 kg (VOLAILLES)",
      "nameFR": "CROQUETTES PREMIUM POUR CHATS STERILISE 2 kg (VOLAILLES)",
      "price": {
        "$numberInt": "55"
      },
      "stock": {
        "$numberInt": "5890"
      },
      "createdAt": {
        "$date": {
          "$numberLong": "1695901223175"
        }
      },
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65176407c9dc92d1519ddb87"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES PREMIUM POUR CHIEN ADULTE 2 kg (POISSON)",
      "nameEN": "CROQUETTES PREMIUM POUR CHIEN ADULTE 2 kg (POISSON)",
      "descriptionFR": "Protéines de volaille déshydratées 40%, riz, maïs, graisse de \r\nvolaille 8% (source d’acide gras oméga6), pré mélange de blé, farine de poisson \r\n6%, pulpe de betterave, huile de poisson 2,5% (source d’acide gras oméga 3-EPA\u0002DHA), foie de volaille 2,5%, FOS fructo-oligosaccharide de betterave, phosphate \r\nmonocalcique, levures, carbonate de calcium, sulfate de calcium, extrait de \r\nlevures, extrait de yucca, chlorure de sodium.",
      "descriptionEN": "Protéines de volaille déshydratées 40%, riz, maïs, graisse de \r\nvolaille 8% (source d’acide gras oméga6), pré mélange de blé, farine de poisson \r\n6%, pulpe de betterave, huile de poisson 2,5% (source d’acide gras oméga 3-EPA\u0002DHA), foie de volaille 2,5%, FOS fructo-oligosaccharide de betterave, phosphate \r\nmonocalcique, levures, carbonate de calcium, sulfate de calcium, extrait de \r\nlevures, extrait de yucca, chlorure de sodium.",
      "composants": " énergie métabolisable 5340,11 kcal/kg, protéines \r\nbrutes 33%, Matières grasses brutes 19,44%, fibres brutes 0,21%, cendres brutes \r\n11,25%, humidité 5,78%, calcium 3,68%, phosphore 1,85%, magnésium 0,12%, \r\nmanganèse 44ppm, fer 228ppm, zinc 142ppm, cuivre 22,24ppm.",
      "composantsEN": " énergie métabolisable 5340,11 kcal/kg, protéines \r\nbrutes 33%, Matières grasses brutes 19,44%, fibres brutes 0,21%, cendres brutes \r\n11,25%, humidité 5,78%, calcium 3,68%, phosphore 1,85%, magnésium 0,12%, \r\nmanganèse 44ppm, fer 228ppm, zinc 142ppm, cuivre 22,24ppm.",
      "additifsFR": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine 2000mg, Vitamine B5-Panthothénate \r\n1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-Biotine 12mg, Vitamine B9-\r\nacide folique 24mg, Vitamine B12-Cyanocobalamine1,2mg, Vitamine C\u0002Phosphate d’ascorbyle de Ca-Na ; chlorure de choline 52000mg, oligo-éléments : \r\nFer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc 5360 mg/kg, \r\nsélénium 4 mg/kg, Iode 120mg/kg, substances aromatiques, Additifs \r\ntechnologiques : Acide citrique E330 2500mg",
      "additifsEN": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine 2000mg, Vitamine B5-Panthothénate \r\n1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-Biotine 12mg, Vitamine B9-\r\nacide folique 24mg, Vitamine B12-Cyanocobalamine1,2mg, Vitamine C\u0002Phosphate d’ascorbyle de Ca-Na ; chlorure de choline 52000mg, oligo-éléments : \r\nFer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc 5360 mg/kg, \r\nsélénium 4 mg/kg, Iode 120mg/kg, substances aromatiques, Additifs \r\ntechnologiques : Acide citrique E330 2500mg",
      "price": {
        "$numberInt": "500"
      },
      "stock": {
        "$numberInt": "6000"
      },
      "minQte": {
        "$numberInt": "100"
      },
      "displayImage": "/1.jpg",
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65176470c9dc92d1519ddb8d"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES PREMIUM POUR CHIOT 2 kg (POISSON)",
      "nameEN": "CROQUETTES PREMIUM POUR CHIOT 2 kg (POISSON)",
      "descriptionFR": " Protéines de volaille déshydratées 46 %, riz, pré mélange de blé, \r\nmaïs, graisse de volaille 6% (source d’acide gras oméga6), pulpe de betterave, \r\nfarine de poisson 5%, huile de poisson 3%(source d’acide gras oméga 3-EPA\u0002DHA), foie de volaille 2,5%, FOS fructo-oligosaccharide de betterave, phosphate \r\nmonocalcique, levures, carbonate de calcium, sulfate de calcium, extrait de \r\nlevures, extrait de yucca, chlorure de sodium.",
      "descriptionEN": " Protéines de volaille déshydratées 46 %, riz, pré mélange de blé, \r\nmaïs, graisse de volaille 6% (source d’acide gras oméga6), pulpe de betterave, \r\nfarine de poisson 5%, huile de poisson 3%(source d’acide gras oméga 3-EPA\u0002DHA), foie de volaille 2,5%, FOS fructo-oligosaccharide de betterave, phosphate \r\nmonocalcique, levures, carbonate de calcium, sulfate de calcium, extrait de \r\nlevures, extrait de yucca, chlorure de sodium.",
      "composants": " énergie métabolisable 5452,82 kcal/kg, protéines \r\nbrutes 36%, matières grasses brutes 20,9%, fibres brutes 0,31%, cendres brutes \r\n11,23%, humidité 6,54%, calcium 3,78%, phosphore 1,70%, magnésium 0,11%, \r\nmanganèse 55,4ppm, fer 225,2ppm, zinc 312,5ppm, cuivre 29,9ppm",
      "composantsEN": " énergie métabolisable 5452,82 kcal/kg, protéines \r\nbrutes 36%, matières grasses brutes 20,9%, fibres brutes 0,31%, cendres brutes \r\n11,23%, humidité 6,54%, calcium 3,78%, phosphore 1,70%, magnésium 0,11%, \r\nmanganèse 55,4ppm, fer 225,2ppm, zinc 312,5ppm, cuivre 29,9ppm",
      "additifsFR": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine 520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique 24mg, Vitamine B12-\r\nCyanocobalamine1,2mg, Vitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure \r\nde choline 52000mg, oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg/kg, \r\nsubstances aromatiques, Additifs technologiques : Acide citrique E330 2500mg",
      "additifsEN": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine 520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique 24mg, Vitamine B12-\r\nCyanocobalamine1,2mg, Vitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure \r\nde choline 52000mg, oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg/kg, \r\nsubstances aromatiques, Additifs technologiques : Acide citrique E330 2500mg",
      "price": {
        "$numberInt": "350"
      },
      "stock": {
        "$numberInt": "520"
      },
      "minQte": {
        "$numberInt": "10"
      },
      "displayImage": "/3.jpg",
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "651764eac9dc92d1519ddb9f"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES PREMIUM POUR CHAT ADULTE 2 kg (POISSONS)",
      "nameEN": "CROQUETTES PREMIUM POUR CHAT ADULTE 2 kg (POISSONS)",
      "descriptionFR": " Protéines de volaille déshydratées 47%, riz, maïs, farine de poisson \r\n9 %( source d’acide gras oméga 3), pré mélange de blé, pulpe de betterave, huile \r\nde poisson 3,4%(source d’acide gras oméga 3-EPA- DHA), foie de volaille 2,5%, \r\ngraisse de volaille 2,2%( source d’acide gras oméga6), FOS fructo-oligosaccharide \r\nde betterave, phosphate monocalcique, levures, carbonate de calcium, sulfate de \r\ncalcium, extrait de levures, extrait de yucca, chlorure de sodium.",
      "descriptionEN": " Protéines de volaille déshydratées 47%, riz, maïs, farine de poisson \r\n9 %( source d’acide gras oméga 3), pré mélange de blé, pulpe de betterave, huile \r\nde poisson 3,4%(source d’acide gras oméga 3-EPA- DHA), foie de volaille 2,5%, \r\ngraisse de volaille 2,2%( source d’acide gras oméga6), FOS fructo-oligosaccharide \r\nde betterave, phosphate monocalcique, levures, carbonate de calcium, sulfate de \r\ncalcium, extrait de levures, extrait de yucca, chlorure de sodium.",
      "composants": "énergie métabolisable 5451,8 kcal/kg, protéines brutes \r\n38%, matières grasses brutes 20 %, fibres brutes 0,21%, cendres brutes 11,25%, \r\nhumidité 5,78%, calcium 4,51%, phosphore 2,47%, magnésium 0,12%, vitamine A \r\n13074 UI/KG, vitamine E 64 UI/KG, manganèse 44ppm, fer 228ppm, zinc 142ppm, \r\ncuivre 22,24ppm",
      "composantsEN": "énergie métabolisable 5451,8 kcal/kg, protéines brutes \r\n38%, matières grasses brutes 20 %, fibres brutes 0,21%, cendres brutes 11,25%, \r\nhumidité 5,78%, calcium 4,51%, phosphore 2,47%, magnésium 0,12%, vitamine A \r\n13074 UI/KG, vitamine E 64 UI/KG, manganèse 44ppm, fer 228ppm, zinc 142ppm, \r\ncuivre 22,24ppm",
      "additifsFR": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique 24mg, Vitamine B12-\r\nCyanocobalamine1,2mg, Vitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure \r\nde choline 52000mg,oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg/mg, \r\nsubstances aromatiques, Additifs technologiques : Acide citrique E330 2500mg",
      "additifsEN": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique 24mg, Vitamine B12-\r\nCyanocobalamine1,2mg, Vitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure \r\nde choline 52000mg,oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg/mg, \r\nsubstances aromatiques, Additifs technologiques : Acide citrique E330 2500mg",
      "price": {
        "$numberInt": "710"
      },
      "stock": {
        "$numberInt": "500"
      },
      "minQte": {
        "$numberInt": "10"
      },
      "displayImage": "/5.jpg",
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65176547c9dc92d1519ddba9"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES PREMIUM POUR CHAT STERILISE 2 kg (POISSON)",
      "nameEN": "CROQUETTES PREMIUM POUR CHAT STERILISE 2 kg (POISSON)",
      "descriptionFR": " Protéines de viande de volaille déshydratée 40%, farine de poisson \r\n18% riz, maïs, pois chiche, amidon de maïs, grains de lin, foie de volaille 2% (avec \r\nlevure et chlorure de sodium), chlorure de sodium, graisse de volaille 1% (source \r\nd’acide gras oméga6), huile de poisson 1% (source d’acide gras oméga 3-EPA\u0002DHA). ",
      "descriptionEN": " Protéines de viande de volaille déshydratée 40%, farine de poisson \r\n18% riz, maïs, pois chiche, amidon de maïs, grains de lin, foie de volaille 2% (avec \r\nlevure et chlorure de sodium), chlorure de sodium, graisse de volaille 1% (source \r\nd’acide gras oméga6), huile de poisson 1% (source d’acide gras oméga 3-EPA\u0002DHA). ",
      "composants": "énergie métabolisable 3460 kcal/kg, protéines brutes \r\n38%, matières grasses brutes 12,6%, fibres brutes 3,06%, cendres brutes 10,5%, \r\nhumidité 6%, calcium 3,7%, phosphore 1,4%, magnésium 0,15%, fer 35 mg/Kg, \r\nzinc 134 mg/Kg, cuivre 12 mg/Kg, manganèse 41mg/Kg, vitamine A 23712 UI/KG, \r\nvitamine D 716 UI/KG.",
      "composantsEN": "énergie métabolisable 3460 kcal/kg, protéines brutes \r\n38%, matières grasses brutes 12,6%, fibres brutes 3,06%, cendres brutes 10,5%, \r\nhumidité 6%, calcium 3,7%, phosphore 1,4%, magnésium 0,15%, fer 35 mg/Kg, \r\nzinc 134 mg/Kg, cuivre 12 mg/Kg, manganèse 41mg/Kg, vitamine A 23712 UI/KG, \r\nvitamine D 716 UI/KG.",
      "additifsFR": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique24mg, Vitamine B12-\r\nCyanocobalamine1,2mg, Vitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure \r\nde choline 52000mg,oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg, \r\nsubstances aromatiques, Additifs technologiques : Acide Citrique E330 2500mg\r\n",
      "additifsEN": "Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000mg/kg, Vitamine B1-Thiamine160mg, Vitamine B2-\r\nRiboflavine520mg, Vitamine PP-Niacine2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine240mg, Vitamine B8-\r\nBiotine12mg, Vitamine B9-acide folique24mg, Vitamine B12-\r\nCyanocobalamine1,2mg, Vitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure \r\nde choline 52000mg,oligo-éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, \r\nManganèse 1840 mg/kg, Zinc 5360 mg/kg, Sélénium 4 mg/kg, Iode 120mg, \r\nsubstances aromatiques, Additifs technologiques : Acide Citrique E330 2500mg\r\n",
      "price": {
        "$numberInt": "520"
      },
      "stock": {
        "$numberInt": "6500"
      },
      "minQte": {
        "$numberInt": "100"
      },
      "displayImage": "/7.jpg",
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "651765bcc9dc92d1519ddbaf"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES PREMIUM POUR CHATON 2 kg (VOLAILLE)",
      "nameEN": "CROQUETTES PREMIUM POUR CHATON 2 kg (VOLAILLE)",
      "descriptionFR": "Protéines de viande de volaille déshydratée 50%, pois chiche, riz, \r\nmaïs, amidon de maïs, graisse de volaille 5% (source d’acide gras oméga 6), grains \r\nde lin, foie de volaille 2% (avec levure et chlorure de sodium), huile de poisson 2% \r\n(source d’acide gras oméga 3-EPA-DHA). ",
      "descriptionEN": "Protéines de viande de volaille déshydratée 50%, pois chiche, riz, \r\nmaïs, amidon de maïs, graisse de volaille 5% (source d’acide gras oméga 6), grains \r\nde lin, foie de volaille 2% (avec levure et chlorure de sodium), huile de poisson 2% \r\n(source d’acide gras oméga 3-EPA-DHA). ",
      "composants": " énergie métabolisable 3390 kcal/kg, protéines brutes \r\n38%, matières grasses brutes 13%, fibres brutes 3,06%, cendres brutes 11%, \r\nhumidité 6%, calcium 3,8%, phosphore 1,47%, magnésium 0,15%, fer 32 mg/Kg, \r\nzinc 129 mg/Kg, cuivre 12 mg/Kg, manganèse 39 mg/Kg, vitamine A 23504 UI/KG, \r\nvitamine D 800 UI/KG.",
      "composantsEN": " énergie métabolisable 3390 kcal/kg, protéines brutes \r\n38%, matières grasses brutes 13%, fibres brutes 3,06%, cendres brutes 11%, \r\nhumidité 6%, calcium 3,8%, phosphore 1,47%, magnésium 0,15%, fer 32 mg/Kg, \r\nzinc 129 mg/Kg, cuivre 12 mg/Kg, manganèse 39 mg/Kg, vitamine A 23504 UI/KG, \r\nvitamine D 800 UI/KG.",
      "additifsFR": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000 mg/kg, Vitamine B1-Thiamine 160mg, Vitamine B2-\r\nRiboflavine 520mg, Vitamine PP-Niacine 2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-Biotine \r\n12mg, Vitamine B9-acide folique 24mg, Vitamine B12-Cyanocobalamine 1,2mg, \r\nVitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure de choline 52000mg, oligo\u0002éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc \r\n5360 mg/kg, Sélénium 4mg/kg, Iode 120mg/kg, substances aromatiques, Additifs \r\ntechnologiques : Acide Citrique E330 2500mg",
      "additifsEN": " Vitamines : vitamine A 600000 UI/KG, vitamine D3 40000 \r\nUI/KG, vitamine E 4000 mg/kg, Vitamine B1-Thiamine 160mg, Vitamine B2-\r\nRiboflavine 520mg, Vitamine PP-Niacine 2000mg, Vitamine B5-\r\nPanthothénate1200mg, Vitamine B6-pyridoxine 240mg, Vitamine B8-Biotine \r\n12mg, Vitamine B9-acide folique 24mg, Vitamine B12-Cyanocobalamine 1,2mg, \r\nVitamine C-Phosphate d’ascorbylede Ca-Na ; chlorure de choline 52000mg, oligo\u0002éléments : Fer 1960 mg/kg, Cuivre 400 mg/kg, Manganèse 1840 mg/kg, Zinc \r\n5360 mg/kg, Sélénium 4mg/kg, Iode 120mg/kg, substances aromatiques, Additifs \r\ntechnologiques : Acide Citrique E330 2500mg",
      "price": {
        "$numberInt": "5800"
      },
      "stock": {
        "$numberInt": "5260"
      },
      "minQte": {
        "$numberInt": "10"
      },
      "displayImage": "/8.jpg",
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65176601c9dc92d1519ddbb5"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES CARNI PLUS POUR CHIEN 20 kg (VOLAILLE)",
      "nameEN": "CROQUETTES CARNI PLUS POUR CHIEN 20 kg (VOLAILLE)",
      "descriptionFR": "Protéines de viandes de volailles déshydratées 30%, maïs, blé, \r\ngluten de maïs, graisses de volailles 6% (source d’acide gras oméga6), huiles de \r\npoisson 3% (source d’acide gras oméga-3-EPA-DHA), foie de volailles 2.5% (avec \r\nlevure et chlorure de sodium).",
      "descriptionEN": "Protéines de viandes de volailles déshydratées 30%, maïs, blé, \r\ngluten de maïs, graisses de volailles 6% (source d’acide gras oméga6), huiles de \r\npoisson 3% (source d’acide gras oméga-3-EPA-DHA), foie de volailles 2.5% (avec \r\nlevure et chlorure de sodium).",
      "composants": "énergie métabolisable 5223 kcal/kg, protéines brutes \r\n37.4%, matières grasses brutes 16.75%, fibres brutes 1.27%, cendres brutes \r\n8.31%, humidité 8.08 %, calcium 2.08%, phosphore 1.06%, magnésium 0.16%, \r\nacide gras oméga-3-EPA-DHA 0.6%, acide gras oméga-6 (acide linoléique-acide \r\narachidonique) 11mg/kg.",
      "composantsEN": "énergie métabolisable 5223 kcal/kg, protéines brutes \r\n37.4%, matières grasses brutes 16.75%, fibres brutes 1.27%, cendres brutes \r\n8.31%, humidité 8.08 %, calcium 2.08%, phosphore 1.06%, magnésium 0.16%, \r\nacide gras oméga-3-EPA-DHA 0.6%, acide gras oméga-6 (acide linoléique-acide \r\narachidonique) 11mg/kg.",
      "additifsFR": "énergie métabolisable 5223 kcal/kg, protéines brutes \r\n37.4%, matières grasses brutes 16.75%, fibres brutes 1.27%, cendres brutes \r\n8.31%, humidité 8.08 %, calcium 2.08%, phosphore 1.06%, magnésium 0.16%, \r\nacide gras oméga-3-EPA-DHA 0.6%, acide gras oméga-6 (acide linoléique-acide \r\narachidonique) 11mg/kg.",
      "additifsEN": "énergie métabolisable 5223 kcal/kg, protéines brutes \r\n37.4%, matières grasses brutes 16.75%, fibres brutes 1.27%, cendres brutes \r\n8.31%, humidité 8.08 %, calcium 2.08%, phosphore 1.06%, magnésium 0.16%, \r\nacide gras oméga-3-EPA-DHA 0.6%, acide gras oméga-6 (acide linoléique-acide \r\narachidonique) 11mg/kg.",
      "price": {
        "$numberInt": "500"
      },
      "stock": {
        "$numberInt": "56200"
      },
      "minQte": {
        "$numberInt": "150"
      },
      "displayImage": "/9.jpg",
      "__v": {
        "$numberInt": "0"
      }
    },
    {
      "_id": {
        "$oid": "65176653c9dc92d1519ddbbb"
      },
      "images": [],
      "createdAt": {
        "$date": {
          "$numberLong": "1696012408727"
        }
      },
      "nameFR": "CROQUETTES CARNI PLUS POUR CHAT 20 kg (VOLAILLE)",
      "nameEN": "CROQUETTES CARNI PLUS POUR CHAT 20 kg (VOLAILLE)",
      "descriptionFR": " Protéines de viandes de volailles déshydratées 30%, maïs, blé, \r\ngluten de maïs, graisse de volailles 6% (source d’acide gras oméga-6), huile de \r\npoisson 3% (source d’acide gras oméga-3 EPA-DHA), foie de volailles 2.5% (avec \r\nlevure et chlorure de sodium). \r\n",
      "descriptionEN": " Protéines de viandes de volailles déshydratées 30%, maïs, blé, \r\ngluten de maïs, graisse de volailles 6% (source d’acide gras oméga-6), huile de \r\npoisson 3% (source d’acide gras oméga-3 EPA-DHA), foie de volailles 2.5% (avec \r\nlevure et chlorure de sodium). ",
      "composants": " énergie métabolisable 5087 kcal/kg, protéines brutes \r\n33.72%, Matières grasses brutes 16.39%, fibres brutes 1.38%, cendres brutes \r\n9.1%, humidité 10%, calcium 1.73%, phosphore 1.31%, magnésium 0.15%, acide \r\ngras oméga-3 EPA-DHA 0.6%, acide gras oméga-6 (acide linoléique-acide \r\narachidonique) 11mg/kg.",
      "composantsEN": " énergie métabolisable 5087 kcal/kg, protéines brutes \r\n33.72%, Matières grasses brutes 16.39%, fibres brutes 1.38%, cendres brutes \r\n9.1%, humidité 10%, calcium 1.73%, phosphore 1.31%, magnésium 0.15%, acide \r\ngras oméga-3 EPA-DHA 0.6%, acide gras oméga-6 (acide linoléique-acide \r\narachidonique) 11mg/kg.",
      "additifsFR": "vitamine A 1680000 UI/KG, vitamine D3 336000 UI/KG, \r\nvitamine E 1312 mg/kg, Fer 8300 mg/kg, Cuivre 1200 mg/kg, Manganèse 1600 \r\nmg/kg, Zinc 8100 mg/kg, Iode 210 mg/kg, Sélénium 20 mg/kg. Additifs \r\ntechnologiques : Antioxydants (BHT 3200 mg/kg, BHA 2560 mg/kg), Propylgallate \r\n1640 mg/kg",
      "additifsEN": "vitamine A 1680000 UI/KG, vitamine D3 336000 UI/KG, \r\nvitamine E 1312 mg/kg, Fer 8300 mg/kg, Cuivre 1200 mg/kg, Manganèse 1600 \r\nmg/kg, Zinc 8100 mg/kg, Iode 210 mg/kg, Sélénium 20 mg/kg. Additifs \r\ntechnologiques : Antioxydants (BHT 3200 mg/kg, BHA 2560 mg/kg), Propylgallate \r\n1640 mg/kg",
      "price": {
        "$numberInt": "60"
      },
      "stock": {
        "$numberInt": "5820"
      },
      "minQte": {
        "$numberInt": "50"
      },
      "displayImage": "/10.jpg",
      "__v": {
        "$numberInt": "0"
      }
    }
  ];
  
  export const footerProductLinks = [
    {
      name: "LA FYRME SAS",
    },
    {
      name: "0751576909",
    },
    {
      name: "contact@lafyrme.com",
    },
    {
      name: "73 RUE DE LYON, 13015 MARSEILLE 15ème Arrondissement",
    },
  ];
  
  export const footercompanyLinks = [
    {
      name: "Chat",
    },
    {
      name: "Chien",
    }
  ];
  export const footermenuLinks=[
    {
      title: "Accueil",
      url: "/",
    },
    {
      title: "À propos",
      url: "/about",
    },
    {
      title: "Produits",
      url: "/products",
    },
    {
      title: "Contact",
      url: "/contact",
    },
    {
      title: "FAQ",
      url: "/faq",
    }
  ]
  
  export const footerSupportLinks = [
    {
      name: "FAQ",
    },
    {
      name: "Reviews",
    },
    {
      name: "Contact Us",
    },
    {
      name: "Shipping",
    },
    {
      name: "Live chat",
    },
  ];
  