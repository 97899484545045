import axios from "axios";
import { server } from "../../server";

// Action types for SousCategories
export const SOUS_CATEGORY_CREATE_REQUEST = "SOUS_CATEGORY_CREATE_REQUEST";
export const SOUS_CATEGORY_CREATE_SUCCESS = "SOUS_CATEGORY_CREATE_SUCCESS";
export const SOUS_CATEGORY_CREATE_FAIL = "SOUS_CATEGORY_CREATE_FAIL";

export const GET_ALL_SOUS_CATEGORIES_REQUEST = "GET_ALL_SOUS_CATEGORIES_REQUEST";
export const GET_ALL_SOUS_CATEGORIES_SUCCESS = "GET_ALL_SOUS_CATEGORIES_SUCCESS";
export const GET_ALL_SOUS_CATEGORIES_FAILED = "GET_ALL_SOUS_CATEGORIES_FAILED";

// Action creators

// Create a SousCategory
export const createSousCategory = (title, categoryId) => async (dispatch) => {
  try {
    dispatch({ type: SOUS_CATEGORY_CREATE_REQUEST });

    const { data } = await axios.post(`${server}/souscategory/create-souscategory`, {
      title,
      categoryId,
    });

    dispatch({
      type: SOUS_CATEGORY_CREATE_SUCCESS,
      payload: data.sousCategory,
    });
  } catch (error) {
    dispatch({
      type: SOUS_CATEGORY_CREATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get all SousCategories
export const getAllSousCategories = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SOUS_CATEGORIES_REQUEST });

    const { data } = await axios.get(`${server}/souscategory/souscategories`);
    dispatch({
      type: GET_ALL_SOUS_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SOUS_CATEGORIES_FAILED,
      payload: error.response.data.message,
    });
  }
};
