import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  error: null,
};
export const categoryReducer = createReducer(initialState, {
  // Create categories actions
  CATEGORY_CREATE_REQUEST: (state) => {
    state.isLoading = true;
  },
  CATEGORY_CREATE_SUCCESS: (state, action) => {
    state.isLoading = false;
    state.allCategories.push(action.payload); // Assuming you want to add the created sousCategory to the list
    state.success = true;
  },
  CATEGORY_CREATE_FAIL: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Get Toutes catégories actions
  GET_ALL_CATEGORIES_REQUEST: (state) => {
    state.isLoading = true;
  },
  GET_ALL_CATEGORIES_SUCCESS: (state, action) => {
    state.isLoading = false;
    state.allCategories = action.payload;
  },
  GET_ALL_CATEGORIES_FAILED: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Clear errors action (if needed)
  clearErrors: (state) => {
    state.error = null;
  },
});