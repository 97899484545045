// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          searchProduct: "Search for a product...",
          FAQPage: {
            tabs: ["LEGAL MENTIONS", "ACCESS AND NAVIGATION", "CONTENT AND PROTECTION OF THE WEBSITE", "RESPONSIBILITIES", "PRIVACY POLICY", "TRACKERS", "APPLICABLE LAW – DISPUTE RESOLUTION"],
            subTabs : [[], [], [],[], ['COLLECTED AND PROCESSED PERSONAL DATA','HOST / DATA CONTROLLER','USER RIGHTS','ENGAGEMENTS OF FYRME S.A.S'], [],[],],
            tab1:'<p>The website is published by the company <strong>LA FYRME S.A.S ("LA FYRME")</strong>, whose information and contact details are indicated below, in accordance with Article 6 of Law No. 2004-575 of June 21, 2004, for trust in the digital economy:<br/><br/>' +
            '<strong>Company form:</strong> Simplified joint-stock company<br/>' +
            '<strong>Share capital:</strong> €4,000<br/>' +
            '<strong>RCS number:</strong> RCS Marseille 952 033 322<br/>' +
            '<strong>VAT number:</strong> FR65952033322<br/>' +
            '<strong>Registered office:</strong> LA FYRME, 13015 MARSEILLE, 173 RUE DE LYON, 13015 MARSEILLE 15<br/>' +
            '<strong>Phone number:</strong> 0751576909<br/>' +
            '<strong>Email address:</strong> contact@lafyrme.com<br/><br/>' +
            'For any questions or information regarding the website, the User can contact LA FYRME S.A.S at the email address above or by sending a registered letter with acknowledgment of receipt to the postal address above.</p>',
            tab2:'<div>' +
            '<p>LA FYRME S.A.S implements all technical solutions at its disposal to ensure permanent access and optimal navigation on the website, without being obliged to achieve a result.</p>' +
        
            '<p>Users expressly acknowledge and accept that access and navigation on the website may be suspended, limited, and/or interrupted, without any liability for LA FYRME S.A.S:</p>' +
        
            '<ul>' +
              '<li>in the case of corrective maintenance, evolutionary maintenance, updates, content modifications, necessary for the proper functioning of the website;</li>' +
              '<li>in the case of malfunctions and/or anomalies attributable to the Internet network, connection, and/or computer equipment used by users;</li>' +
              '<li>in the case of fraudulent intrusion by any third party and/or any virus into the networks, connections, and computer equipment used by users;</li>' +
              '<li>in the case of force majeure or any other event beyond the control of LA FYRME S.A.S;</li>' +
              '<li>for any other reason and/or action deemed useful and necessary by LA FYRME S.A.S for the proper functioning of the website.</li>' +
            '</ul>' +
        
            '<p>Users must, in all circumstances, ensure the maintenance and regular updating of computer equipment, operating systems, antivirus software, and browsers used to access and navigate the website.</p>' +
          '</div>',
            tab3:'<div>' +
            '<p>The information, photographs, illustrations, and other indications contained on the website are provided for informational purposes only and do not replace or substitute for personalized advice and information that may be given to the User by contacting LA FYRME S.A.S at the coordinates referred to in Article 1.</p>' +
        
            '<p>Although LA FYRME S.A.S takes the utmost care to ensure the quality and reliability of the information, photographs, illustrations, and other indications contained on the website, these elements do not constitute a contractual commitment or an explicit or implicit guarantee of validity, completeness, timeliness, and/or perfect similarity to the products and services of LA FYRME S.A.S.</p>' +
        
            '<p>Hypertext links set up to other websites, if any, have been the subject of express and prior authorization. Since the links are provided for the personal convenience of Users, LA FYRME S.A.S exercises no control over these websites and accepts no responsibility for them, the web pages remaining the sole responsibility of their owners.</p>' +
        
            '<p>For any questions, the User is invited to contact LA FYRME S.A.S at the coordinates referred to in Article 1.</p>' +
        
            '<p>The general structure of the website, the texts, images and photographs, data, animated or non-animated graphic elements, and more generally, all the elements that make up the website are creations and works exclusively owned by LA FYRME S.A.S, likely to be protected under the Intellectual Property Code and international conventions.</p>' +
        
            '<p>This website, as well as all the elements that compose it, cannot in any case be modified, reproduced, displayed, presented, distributed, or used for public or commercial purposes without the express, prior, and written authorization of LA FYRME S.A.S.</p>' +
        
            '<p>Reproduction of a page of the website or any other element composing the website in a frame not belonging and/or not concerning LA FYRME S.A.S, as well as the insertion of a page of the website or any other element composing it into the page of a site not belonging to LA FYRME S.A.S, is strictly prohibited.</p>' +
          '</div>',
            tab4:'<div>' +
            '<p>As the editor of the website, LA FYRME S.A.S\'s liability is strictly limited to the content it has edited on the website.</p>' +
            '<p>LA FYRME S.A.S\'s liability cannot be sought, at any time and on any legal basis whatsoever, in the event of:</p>' +
            '<ul>' +
              '<li>suspension, limitation, and/or interruption of access and navigation on the website for the cases indicated in Article 2 above;</li>' +
              '<li>use of the website in an abnormal, illegal, and/or contrary manner to these T&amp;Cs;</li>' +
              '<li>failure of Users to comply with any of the clauses of these T&amp;Cs;</li>' +
              '<li>force majeure.</li>' +
            '</ul>' +
            '<p>As part of accessing and navigating the website, Users expressly undertake to:</p>' +
            '<ul>' +
              '<li>scrupulously respect these T&amp;Cs;</li>' +
              '<li>not exchange, disseminate, and/or publish, in any way, illegal, offensive, defamatory, distorted, insulting, and/or harmful content to the interests of LA FYRME S.A.S and the website.</li>' +
            '</ul>' +
          '</div>',
            tab5:'<div>' +
            '<p>Personal data concerning the User and likely to be collected when accessing and navigating the website include: first names, last names, address, phone, age, gender, email address, and date of birth (hereinafter "Personal Data").</p>' +
            '<p>This Personal Data may be collected, in particular, when the User enters and completes a form for the purpose of creating an account to:</p>' +
            '<ul>' +
              '<li>make online purchases or subscribe to a subscription;</li>' +
              '<li>receive the newsletter issued by LA FYRME S.A.S;</li>' +
              '<li>receive commercial offers.</li>' +
            '</ul>' +
            '<p>The collected Personal Data will be kept by LA FYRME S.A.S under reasonable security conditions for a reasonable duration and strictly necessary for the aforementioned purposes, unless the User requests deletion before the expiration of this duration.</p>' +
          '</div>',
            tab6:'<div>' +
            '<p>As indicated above, the website is hosted by the company Web DIDO SOFTWARE, which can be directly contacted at the coordinates referred to in Article 1.</p>' +
            '<p>The Data Controller for Personal Data is LA FYRME S.A.S, which can be directly contacted at the following coordinates:</p>' +
            '<ul>' +
              '<li><a href="mailto:contact@lafyrme.com">contact@lafyrme.com</a></li>' +
              '<li>Postal address indicated in Article 1.</li>' +
            '</ul>' +
          '</div>',
            tab7:'<div>' +
            '<p>In accordance with the regulations regarding the processing of personal data, the User has the following rights over the Personal Data collected by the website:</p>' +
            '<ul>' +
              '<li>The User may be aware of, update, modify, or request the deletion of Personal Data concerning him/her;</li>' +
              '<li>The User has the right to request the limitation and/or object to the processing of his/her Personal Data from the website;</li>' +
              '<li>The User has the right to request the portability and transfer of his/her Personal Data from the website to any other website.</li>' +
            '</ul>' +
            '<p>The User has the possibility to exercise the above-mentioned rights by contacting LA FYRME S.A.S directly at the following email address: <a href="mailto:contact@lafyrme.com">contact@lafyrme.com</a></p>' +
            '<p>So that LA FYRME S.A.S can comply with his request, the User is required to provide his first name, last name, email address, and any other information useful for verifying his identity.</p>' +
            '<p>The User also has the right to lodge a complaint with the CNIL (National Commission for Information Technology and Liberties - <a href="https://www.cnil.fr">www.cnil.fr</a>) or any competent judge.</p>' +
          '</div>',
            tab8:'<div>' +
            '<p>LA FYRME S.A.S undertakes to collect and process the User\'s Personal Data in accordance with Law No. 78-17 of January 6, 1978, and Regulation (EU) 2016/679 of April 27, 2016, on the protection of personal data (<strong>GDPR</strong>).</p>' +
            '<p>In this regard, LA FYRME S.A.S undertakes in particular to:</p>' +
            '<ul>' +
              '<li>Respect the purposes for which the User\'s Personal Data was collected;</li>' +
              '<li>Obtain the prior and express consent of the User before using Personal Data for sending newsletters, promotional offers, and other information relating to products offered by LA FYRME S.A.S and/or its partners;</li>' +
              '<li>Not transmit the Personal Data of Users without prior information and acceptance from them;</li>' +
              '<li>Protect the Personal Data of Users under reasonable security conditions;</li>' +
              '<li>Inform the User, by any means and without undue delay, in the event that the integrity, confidentiality, and/or security of his/her Personal Data are compromised.</li>' +
            '</ul>' +
          '</div>',
            tab9:'<div>' +
            '<p>Trackers or cookies are files made up of letters and numbers, recorded on the browser and/or hard drive used by the User.</p>' +
            '<p>The website may use cookies for purposes such as processing statistics and anonymous information about website traffic, facilitating access, navigation, and improving the service of the website for the User\'s comfort.</p>' +
            '<p>LA FYRME S.A.S undertakes to collect the express and prior consent of the User for any use of cookies involving the storage and/or analysis of his/her Personal Data.</p>' +
            '<p>The User has the option to refuse the recording of all or part of the cookies, in particular by configuring his/her browser software(s).</p>' +
            '<p>If the User decides to refuse the recording of cookies, access and navigation on the website remain possible, however, it is specified that malfunctions and disruptions may occur due to the refusal of cookies, without the liability of LA FYRME S.A.S being incurred for this reason.</p>' +
            '<p>Cookies recorded and used from the website are kept by LA FYRME S.A.S for a reasonable duration and strictly necessary for the aforementioned purposes, and at most for a duration of 13 months after their deposit.</p>' +
          '</div>',
            tab10:'<div>' +
            '<p>These T&amp;Cs are governed by the provisions of French law, to the exclusion of any other foreign legislation and/or regulation, regardless of the country in which Users are located and/or from which access to the website is made.</p>' +
            '<p>Any dispute (contractual, extra-contractual, tortious) relating to these T&amp;Cs will, in the absence of an amicable settlement, be submitted to the exclusive jurisdiction of the French courts designated materially and territorially competent in accordance with the rules of French civil procedure.</p>' +
          '</div>',
          },
          NewsletterForm: {
            emailPlaceholder: 'Enter Email',
            subscribeButton: "Subscribe",
            followUs:'Follow us :',
          },
          welcome: 'Welcome to our app!',
          selectLanguage: 'Select a Language:',
          best: 'Best deals',
          tem:"Testimonials",
          Categories: {
            r1: 'Respect for the ancestral regime',
            r2: 'Made Without Grains - Super Premium',
            c1: 'Palette order',
            c2: 'Reference mix',
            p1: 'Payment in installments',
            p2: 'from 20 bags',
            t1: 'Degressive prices',
            t2: 'from 10 bags',
            f1: 'Formats adapted to your needs',
            f2: '20kg from 20 bags',
          },
          hero: {
            title: 'EXCEPTIONAL KIBBLE FOR A HEALTHY DIET Discover premium nutrition for animals in top shape',
          },
          contact: {
            title: 'Contact Us',
            nom: 'Last Name',
            prenom: 'Frist Name',
            email: 'email',
            message: 'message',
            envoyer: 'Send',
            temps:'Monday to Friday from 9 a.m to 6 p.m',
            info1:"Do you want to order more than 10 bags of kibble?",
            info2:"Meeting on "
          },
          login: {
            title: "Log in to your account",
            email: "Email",
            password: "Password",
            loginBtn: "Login",
            wantToBeSeller: "Do you want to be a seller?",
            requestSeller: "Request seller"
          },
          footer: {
            enterprise: 'Company',
            category: 'Category',
            menu: 'Menu',
          },
          navbar: {
            home: 'Home',
            about: 'About',
            dog: 'Dog',
            cat: 'Cat',
            format20kg: 'Format 20 kg',
            advantages: 'Advantages Pro',
            dummy: 'Dummy',
            contact: 'Contact',
            faq: 'FAQ',
            produit:'Products'
          },
          signup: {
            title: "Sign Up to Become a Seller",
            nom: "Name",
            prenom: "First Name",
            societe: "Company",
            num: "Phone Number",
            email: "Email Address",
            addPhoto: "Add Photo",
            submitBtn: "Become a Seller",
            alreadyHaveAccount: "Already have an account?",
            signIn: "Sign In",
            success: "Sign Up Successful!"
          },
          about: {
            engagement: 'OUR COMMITMENT',
            partnership: 'OUR PARTNERSHIP',
            nutrition: 'FEEDING YOUR COMPANION WITHOUT BREAKING THE BANK',
            future: 'OUR FUTURE',
            title: 'About Us',
            engagementContent: 'LA FYRME SAS is a dynamic and innovative company. Founded in Marseille, France, we are committed to providing exceptional food solutions for pets that deserve optimal nutrition and a happy life. Since our inception, our mission has been to make a significant difference in the lives of animals and their owners. Our dedicated team is driven by a deep passion for animals and an unwavering desire to improve their well-being through the highest quality products. At LA FYRME SAS, we believe that nutrition is the key to a healthy and fulfilling life for our four-legged friends. That is why every product we offer is carefully designed to meet the specific needs of each animal. Our range of My Pet\'s products embodies our commitment to excellence in nutrition, offering tasty and balanced options that contribute to the vitality and happiness of every animal.',
            partnershipContent: 'We are delighted to offer you our range of premium quality food, providing high-quality nutrition for your beloved pets. At La FYRME, we firmly believe that every animal deserves healthy and balanced nutrition to lead a happy and healthy life. To achieve this goal, we have established a strategic partnership with MY PET\'S, a brand that has been producing high-quality pet food in Tunisia for 20 years, renowned for its exceptional product quality and rigorous quality controls in accordance with European CE standards. Our range of pet food includes a wide variety to meet the diverse needs of cats and dogs. We use only the best ingredients, including fresh meat proteins, to provide optimal nutrition. Our pet foods are manufactured in state-of-the-art facilities and undergo strict quality controls to ensure both safety and quality. We also offer specialized options for pets with specific dietary needs, such as food allergies or digestive issues.',
            nutritionContent: 'Furthermore, we are proud to offer you the best prices on the market without compromising the premium quality of our products. In this way, we not only preserve the purchasing power of pet owners but also ensure excellent nutrition for their four-legged companions.',
            futureContent: 'We have big ambitions for the future. We plan to expand our presence in France by establishing partnerships with specialized shops and trusted professionals. Additionally, we plan to develop specific product lines to meet the particular needs of certain breeds or specific requirements.',
          },
          profileSidebar: {
            profile: "Profile",
            orders: "Orders",
            changePassword: "Change Password",
            address: "Address",
            adminDashboard: "Admin Dashboard",
            logout: "Log out",
            logoutSuccess: "Log out successful!"
          },
          profileContent:{
            fullName: "Full Name",
            emailAddress: "Email Address",
            phoneNumber: "Phone Number",
            enterPassword: "Enter Password",
            update: "Update",
            avatarUpdated: "Avatar updated",
          },
          allOrders: {
            status: "Status",
            itemsQty: "Items Qty",
            total: "Total"
          },
          changePassword: {
            title: "Change Password",
            oldPassword: "Enter your old password",
            newPassword: "Enter your new password",
            confirmPassword: "Enter your confirm password",
            update: "Update",
            successMessage: "Password updated successfully",
            errorMessage: "Failed to update password"
          },
          address: {
            fillAllFields: "Please fill all the fields!",
            myAddresses: "My Addresses",
            addNew: "Add New",
            noSavedAddress: "You do not have any saved address!",
            addNewAddress: "Add New Address",
            country: "Country",
            chooseCity: "Choose your City",
            address1: "Address 1",
            address2: "Address 2",
            zipCode: "Zip Code",
            addressType: "Address Type",
            submit: "Submit"
          },
        },
      },
      fr: {
        translation: {
          address: {
            fillAllFields: "Veuillez remplir tous les champs !",
            myAddresses: "Mes adresses",
            addNew: "Ajouter Nouveau",
            noSavedAddress: "Vous n'avez aucune adresse enregistrée !",
            addNewAddress: "Ajouter une nouvelle adresse",
            country: "Pays",
            chooseCity: "Choisissez votre ville",
            address1: "Adresse 1",
            address2: "Adresse 2",
            zipCode: "Code postal",
            addressType: "Type d'adresse",
            submit: "Soumettre"
          },
          changePassword: {
            title: "Changer le mot de passe",
            oldPassword: "Entrez votre ancien mot de passe",
            newPassword: "Entrez votre nouveau mot de passe",
            confirmPassword: "Entrez votre mot de passe de confirmation",
            update: "Mettre à jour",
            successMessage: "Mot de passe mis à jour avec succès",
            errorMessage: "Échec de la mise à jour du mot de passe"
          },
          allOrders: {
            status: "Statut",
            itemsQty: "Quantité d'articles",
            total: "Total"
          },
          profileContent:{
            fullName: "Nom complet",
            emailAddress: "Adresse e-mail",
            phoneNumber: "Numéro de téléphone",
            enterPassword: "Entrer le mot de passe",
            update: "Mettre à jour",
            avatarUpdated: "Avatar mis à jour",
          },
          profileSidebar: {
            profile: "Profil",
            orders: "Commandes",
            changePassword: "Changer le mot de passe",
            address: "Adresse",
            adminDashboard: "Tableau de bord administrateur",
            logout: "Se déconnecter",
            logoutSuccess: "Déconnexion réussie !"
          },
          signup: {
            title: "Inscrivez-vous pour devenir vendeur",
            nom: "Nom",
            prenom: "Prénom",
            societe: "Société",
            num: "Numéro de téléphone",
            email: "Adresse e-mail",
            addPhoto: "Ajouter une photo",
            submitBtn: "Devenir vendeur",
            alreadyHaveAccount: "Vous avez déjà un compte?",
            signIn: "Se connecter",
            success: "Inscription réussie !"
          },
          login: {
            title: "Connectez-vous à votre compte",
            email: "Email",
            password: "Mot de passe",
            loginBtn: "Se connecter",
            wantToBeSeller: "Tu veux être vendeur ?",
            requestSeller: "Demande vendeur"
          },
          searchProduct: "Rechercher un produit...",
          FAQPage: {
            tabs: ['MENTIONS LEGALES', 'ACCES ET NAVIGATION', 'CONTENU ET PROTECTION DU SITE INTERNET','RESPONSABILITES', 'POLITIQUE DE CONFIDENTIALITE', 'TRACEURS','DROIT APPLICABLE – REGLEMENT DES LITIGES'],
            subTabs : [[], [], [],[], ['DONNEES PERSONNELLES COLLECTEES ET TRAITEES','HEBERGEUR / RESPONSABLE DU TRAITEMENT','DROIT DES UTILISATEURS','ENGAGEMENTS DE LA FYRME S.A.S'], [],[],],
            tab1:'Le Site Internet est édité par la société <strong>LA FYRME S.A.S (« LA FYRME »)</strong>, dont' +
            ' l’ensemble des informations et coordonnées sont indiquées ci-après,<br/>' +
            'conformément à l’article 6 de la Loi n°2004-575 du 21 juin 2004 pour la' +
            ' confiance dans l’économie numérique :<br/><br/>' +
            '<strong>Forme de la société :</strong> Société par actions simplifiée<br/>' +
            '<strong>Capital social :</strong> 4 000 €\<br/>' +
            '<strong>Numéro RCS :</strong> RCS Marseille 952 033 322<br/>' +
            '<strong>Numéro TVA :</strong> FR65952033322<br/>' +
            '<strong>Siège social :</strong> LA FYRME, 13015 MARSEILLE, 173 RUE DE LYON, 13015<br/>' +
            'MARSEILLE 15<br/>' +
            '<strong>Numéro de téléphone :</strong> 0751576909<br/>' +
            '<strong>Adresse e-mail :</strong> contact@lafyrme.com<br/><br/>' +
            'Pour toute question ou demande d’information concernant le Site Internet, ' +
            'l’Utilisateur peut contacter LA FYRME S.A.S à l’adresse électronique ci-dessus<br/>' +
            'ou en envoyant une lettre recommandée avec accusé de réception à l’adresse ' +
            'postale ci-dessus.',
            tab2:'<div>'+
            '<p>LA FYRME S.A.S met en œuvre toutes les solutions techniques à sa disposition pour permettre un accès permanent et une navigation optimale sur le Site Internet, sans toutefois être tenue à une obligation de résultat.</p>'+
          
            '<p>Les Utilisateurs reconnaissent et acceptent expressément que l’accès et la navigation sur le Site Internet peuvent être suspendus, limités et/ou interrompus, sans responsabilité pour LA FYRME S.A.S :</p>'+
          
            '<ul>'+
              '<li>en cas de maintenance corrective, maintenance évolutive, mises à jour, modifications de contenu, rendus nécessaires pour le bon fonctionnement du Site Internet ;</li>'+
              '<li>en cas de dysfonctionnements et/ou d’anomalies imputables au réseau Internet, à la connexion et/ou aux équipements informatiques utilisés par les Utilisateurs ;</li>'+
              '<li>en cas d’intrusion frauduleuse de tout tiers et/ou tout virus dans les réseaux, connexions et équipements informatiques utilisés par les Utilisateurs ;</li>'+
              '<li>en cas d’évènement de force majeure ou de tout autre évènement placé en dehors du contrôle de LA FYRME S.A.S ;</li>'+
              '<li>pour toute autre raison et/ou action jugée utile et nécessaire par LA FYRME S.A.S pour le bon fonctionnement du Site Internet.</li>'+
            '</ul>'+
          
            '<p>Les Utilisateurs doivent, en toutes circonstances, s’assurer de la maintenance et la mise à jour régulière des équipements informatiques, systèmes d’exploitation, logiciels anti-virus et navigateurs utilisés pour l\'accès et la navigation sur le Site Internet.</p>'+
          '</div>',
            tab3:'<div>'+
            '<p>Les informations, photographies, illustrations et autres indications contenues dans le Site Internet sont fournies uniquement à titre informatif et indicatif à l’Utilisateur et ne sauraient remplacer ou se substituer aux conseils et aux informations personnalisés susceptibles d’être donnés à l’Utilisateur en contactant LA FYRME S.A.S aux coordonnées visées à l’article 1.</p>'+
          
            '<p>Bien que LA FYRME S.A.S prenne le plus grand soin de s’assurer de la qualité et de la fiabilité des informations, photographies, illustrations et autres indications contenues dans le Site Internet, ces éléments ne constituent pas un engagement contractuel, ni une garantie, explicite ou implicite, de validité, d’exhaustivité, d’actualité et/ou de similitude parfaite avec les produits et services de LA FYRME S.A.S.</p>'+
          
            '<p>Les liens hypertextes mis en place en direction d’autres sites Internet, le cas échéant, ont fait l’objet d’une autorisation expresse et préalable. Les liens étant fournis pour la convenance personnelle des Utilisateurs, LA FYRME S.A.S n’exerce aucun contrôle sur ces sites Internet et n’accepte aucune responsabilité à leur égard, les pages Internet restant de la seule responsabilité de leurs titulaires.</p>'+
          
            '<p>Pour toute question, l’Utilisateur est invité à contacter LA FYRME S.A.S aux coordonnées visées à l’article 1.</p>'+
          
            '<p>La structure générale du Site Internet, les textes, les images et photographies, les données, les éléments graphiques animés ou non et plus généralement, l’ensemble des éléments qui composent le Site Internet constituent des créations et œuvres appartenant exclusivement à LA FYRME S.A.S, susceptibles d’être protégées au sens du Code de la propriété intellectuelle et des conventions internationales.</p>'+
          
            '<p>Le présent Site Internet, de même que l’ensemble des éléments le composant ne peuvent en aucun cas être modifiés, reproduits, affichés, présentés, distribués ou utilisés à des fins d’intérêt public ou commercial, sans l’autorisation expresse, préalable et écrite de LA FYRME S.A.S.</p>'+
          
            '<p>La reproduction d’une page du Site Internet ou de tout autre élément composant le Site Internet dans un cadre n’appartenant et/ou ne concernant pas LA FYRME S.A.S, de même que l’insertion d’une page du Site Internet ou de tout autre élément le composant dans la page d’un site n’appartenant pas à LA FYRME S.A.S est strictement interdite.</p>'+
            '</div>',
            tab4:'<div>'+
            '<p>En qualité d’éditeur du Site Internet, la responsabilité de LA FYRME S.A.S est strictement limitée aux contenus qu’elle a elle-même édités sur le Site Internet.</p>'+
            '<p>La responsabilité de LA FYRME S.A.S ne saurait être recherchée, à quelque moment et sur quelque fondement juridique que ce soient, en cas :</p>'+
            '<ul>'+
              '<li>de suspension, de limitation et/ou d’interruption de l’accès et de la navigation sur le Site Internet pour les cas indiqués à l’article 2 ci-dessus ;</li>'+
              '<li>d’utilisation du Site Internet de façon anormale, illicite et/ou contraire aux présentes CGU ;</li>'+
              '<li>de manquement des Utilisateurs à l’une quelconque des clauses des présentes CGU ;</li>'+
              '<li>d’évènement de force majeure.</li>'+
            '</ul>'+
            '<p>Dans le cadre de l’accès et de la navigation sur le Site Internet, les Utilisateurs s’engagent expressément à :</p>'+
            '<ul>'+
              '<li>respecter scrupuleusement les présentes CGU ;</li>'+
              '<li>ne pas échanger, diffuser et/ou publier, d’une quelconque façon, des contenus illicites, offensant, diffamatoires, déformés, insultant et/ou préjudiciables aux intérêts de LA FYRME S.A.S et du Site Internet.</li>'+
            '</ul>'+
          '</div>',
            tab5:'<div>'+
            '<p>Les données à caractère personnel concernant l’Utilisateur et susceptibles d’être collectées à l’occasion de l’accès et de la navigation sur le Site Internet concernent notamment : les prénoms, noms, adresse, téléphone, âge, sexe, adresse de messagerie électronique et date de naissance (Ci-après les « Données Personnelles »).</p>'+
            '<p>Ces Données Personnelles sont susceptibles d’être collectées notamment lorsque l’Utilisateur saisit et renseigne un formulaire aux fins de créer un compte pour :</p>'+
            '<ul>'+
              '<li>procéder à des achats en ligne ou souscrire à un abonnement ;</li>'+
              '<li>recevoir la newsletter diffusée par LA FYRME S.A.S ;</li>'+
              '<li>recevoir des offres commerciales.</li>'+
            '</ul>'+
            '<p>Les Données Personnelles collectées seront conservées par LA FYRME S.A.S dans des conditions raisonnables de sécurité pour une durée raisonnable et strictement nécessaire aux finalités susvisées, à moins que l’Utilisateur n’en demande la suppression avant l’expiration de cette durée.</p>'+
          '</div>',
            tab6:'<div>'+
            '<p>Comme indiqué ci-dessus, le Site Internet est hébergé par la société Web DIDO SOFTWARE, laquelle peut être directement contactée aux coordonnées visées à l’article 1.</p>'+
            '<p>Le Responsable du traitement des Données Personnelles est LA FYRME S.A.S, laquelle peut être directement contactée aux coordonnées suivantes :</p>'+
            '<ul>'+
              '<li><a href="mailto:contact@lafyrme.com">contact@lafyrme.com</a></li>'+
              '<li>Adresse postale indiquée à l\'article 1.</li>'+
            '</ul>'+
          '</div>',
            tab7:'<div>'+
            '<p>Conformément à la réglementation relative au traitement des données à caractère personnel, l’Utilisateur dispose des droits ci-après énumérés sur les Données Personnelles collectées par le Site Internet :</p>'+
            '<ul>'+
              '<li>L’Utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des Données Personnelles le concernant ;</li>'+
              '<li>L’Utilisateur a le droit de demander la limitation et/ou de s’opposer au traitement de ses Données Personnelles depuis le Site Internet ;</li>'+
              '<li>L’Utilisateur a le droit de demander la portabilité et le transfert de ses Données Personnelles depuis le Site Internet vers tout autre site internet.</li>'+
           ' </ul>'+
            '<p>L’Utilisateur a la possibilité d\'exercer les droits susvisés en contactant directement LA FYRME S.A.S à l\'adresse de courriel électronique suivante : <a href="mailto:contact@lafyrme.com">contact@lafyrme.com</a></p>'+
            '<p>Afin que LA FYRME S.A.S puisse faire droit à sa demande, l’Utilisateur est tenu de lui communiquer son prénom, son nom, son adresse e-mail, ainsi que toute autre information utile à la vérification de son identité.</p>'+
            '<p>L’Utilisateur dispose également du droit d\'introduire une réclamation auprès de la CNIL (Commission Nationale de l\'Informatique et des Libertés – <a href="https://www.cnil.fr">www.cnil.fr</a>) ou tout juge compétent.</p>'+
          '</div>',
            tab8:'<div>'+
            '<p>LA FYRME S.A.S s’engage à collecter et à traiter les Données Personnelles des Utilisateurs conformément à la Loi n° 78-17 du 6 janvier 1978, ainsi qu’au Règlement européen n°2016/679 du 27 avril 2016 relatif à la protection des données personnelles (<strong>RGPD</strong>).</p>'+
            '<p>À ce titre, LA FYRME S.A.S s’engage notamment à :</p>'+
            '<ul>'+
              '<li>Respecter les finalités pour lesquelles les Données Personnelles des Utilisateurs ont été collectées ;</li>'+
              '<li>Recueillir le consentement préalable et exprès de l’Utilisateur avant toute utilisation des Données Personnelles pour l’envoi de newsletter, offres promotionnelles et autres informations relatives aux produits proposés par LA FYRME S.A.S et/ou ses partenaires ;</li>'+
              '<li>Ne pas transmettre les Données Personnelles des Utilisateurs sans information et acceptation préalables de la part de ces derniers ;</li>'+
              '<li>Protéger les Données Personnelles des Utilisateurs dans des conditions raisonnables de sécurité ;</li>'+
              '<li>Informer l’Utilisateur, par tous moyens et sans retard injustifié, dans le cas où l’intégrité, la confidentialité et/ou la sécurité de ses Données Personnelles seraient compromises.</li>'+
            '</ul>'+
            '</div>',
            tab9:'<div>'+
            '<p>Les traceurs ou cookies sont des fichiers formés de lettres et de chiffres, enregistrés sur le navigateur et/ou le disque dur utilisé par l’Utilisateur.</p>'+
            '<p>Le Site Internet peut avoir recours aux cookies aux fins notamment de traiter des statistiques et des informations anonymes sur le trafic du Site Internet, de faciliter l’accès, la navigation et d’améliorer le service du Site Internet pour le confort de l’Utilisateur.</p>'+
            '<p>LA FYRME S.A.S s’oblige à recueillir le consentement exprès et préalable de l’Utilisateur pour toute utilisation de cookies impliquant la sauvegarde et/ou l’analyse de ses Données Personnelles.</p>'+
            '<p>L’Utilisateur a la possibilité de refuser l’enregistrement de tout ou partie des cookies, notamment en procédant au paramétrage de son ou de ses logiciel(s) de navigation.</p>'+
            '<p>Si l’Utilisateur décide de refuser l’enregistrement de cookies, l’accès et la navigation sur le Site Internet demeurent possibles, étant toutefois précisé que des dysfonctionnements et perturbations peuvent avoir lieu du fait du refus des cookies, sans que la responsabilité de LA FYRME S.A.S ne puisse être engagée à ce titre.</p>'+
            '<p>Les cookies enregistrés et utilisés depuis le Site Internet sont conservés par LA FYRME S.A.S pour une durée raisonnable et strictement nécessaire aux finalités susvisées, et au maximum pour une durée de 13 mois après leur dépôt.</p>'+
          '</div>',
            tab10: '<div>'+
            '<p>Les présentes CGU sont régies par les dispositions de la loi française, à l’exclusion de toute autre législation et/ou réglementation étrangère, quel que soit le pays dans lequel sont implantés les Utilisateurs et/ou depuis lequel s’effectue l’accès au Site Internet.</p>'+
            '<p>Tout litige (contractuel, extracontractuel, délictuel) ayant trait aux présentes CGU sera, à défaut de règlement amiable, soumis à la compétence exclusive des juridictions françaises désignées matériellement et territorialement compétentes en application des règles de procédure civile françaises.</p>'+
          '</div>',
          },
          NewsletterForm: {
            emailPlaceholder: 'Entrez votre e-mail',
            subscribeButton: "S'abonner",
            followUs:'Suivez-nous :',
          },
          welcome: 'Bienvenue dans notre application!',
          selectLanguage: 'Sélectionnez une langue :',
          best: 'Meilleures affaires',
          tem:"Témoignages",
          Categories: {
            r1: 'Respect du régime ancestral',
            r2: 'Fabrique Sans Céréales - Super Premium',
            c1: 'Commande à la palette',
            c2: 'Panachage des références',
            p1: 'Paiement en plusieurs fois',
            p2: 'à partir de 20 sacs',
            t1: 'Tarifs dégressifs',
            t2: 'à partir de 10 sacs',
            f1: 'Formats adaptés à vos besoins',
            f2: '20kg à partit de 20 sacs',
          },
          hero: {
            title: 'DES CROQUETTES D’EXCEPTION POUR UNE ALIMENTATION SAINE Découvrez une nutrition de premier choix pour des animaux en pleine forme',
          },
          contact: {
            title: 'Contactez-nous',
            nom: 'nom',
            prenom: 'prenom',
            email: 'email',
            message: 'message',
            envoyer: 'envoyer',
            temps:'Du lundi au vendredi de 9h à 18h',
            info1:"Vous souhaitez commander plus de 10 sacs de croquettes ?",
            info2:"Rendez-vous sur "
          },
          footer: {
            enterprise: 'Entreprise',
            category: 'Catégorie',
            menu: 'Menu',
          },
          navbar: {
            home: 'Accueil',
            about: 'À propos',
            dog: 'Chien',
            cat: 'Chat',
            format20kg: 'Format 20 kg',
            advantages: 'Avantages Pro',
            dummy: 'Dummy',
            contact: 'Contact',
            faq: 'FAQ',
            produit:'Produits'
          },
          about: {
            engagement: 'NOTRE ENGAGEMENT',
            partnership: 'NOTRE PARTENARIAT',
            nutrition: 'BIEN NOURRIR SON COMPAGNON SANS SE RUINER',
            future: 'NOTRE AVENIR',
            title: 'À propos',
            engagementContent: 'LA FYRME SAS est une entreprise dynamique et innovante. Fondée à Marseille, en France, nous nous engageons à fournir des solutions alimentaires exceptionnelles pour les animaux de compagnie qui méritent une nutrition optimale et une vie heureuse. Depuis notre création, notre mission a été de créer une différence significative dans la vie des animaux et de leurs propriétaires. Notre équipe dévouée est animée par une passion profonde pour les animaux et une volonté inébranlable d\'améliorer leur bien-être grâce à des produits de la plus haute qualité. Chez LA FYRME SAS, nous croyons que la nutrition est la clé d\'une vie saine et épanouissante pour nos amis à quatre pattes. C\'est pourquoi chaque produit que nous proposons est soigneusement conçu pour répondre aux besoins spécifiques de chaque animal. Notre gamme de produits My Pet\'s incarne notre engagement envers l\'excellence en matière de nutrition, en offrant des options savoureuses et équilibrées qui contribuent à la vitalité et au bonheur de chaque animal.',
            partnershipContent: 'Nous sommes ravis de vous proposer notre gamme d\'aliments de qualité supérieure, fournissant une nutrition de haute qualité pour vos animaux de compagnie bien-aimés. Chez La FYRME, nous croyons fermement que chaque animal mérite une alimentation saine et équilibrée pour mener une vie heureuse et en bonne santé. Pour atteindre cet objectif, nous avons établi un partenariat stratégique avec MY PET\'S, une marque qui produit depuis 20 ans des aliments pour animaux de haute qualité en Tunisie, réputée pour son exceptionnelle qualité de produit et ses rigoureux contrôles de qualité conformément aux normes européennes CE. Notre gamme d\'aliments pour animaux de compagnie comprend une grande variété pour répondre aux besoins diversifiés des chats et des chiens. Nous n\'utilisons que les meilleurs ingrédients, y compris des protéines de viande fraîche, pour offrir une nutrition optimale. Nos aliments pour animaux de compagnie sont fabriqués dans des installations de pointe et font l\'objet de contrôles de qualité stricts pour garantir à la fois la sécurité et la qualité. Nous proposons également des options spécialisées pour les animaux de compagnie ayant des besoins alimentaires spécifiques, tels que des allergies alimentaires ou des problèmes de digestion.',
            nutritionContent: 'De plus, nous sommes fiers de vous offrir les meilleurs prix sur le marché sans compromettre la qualité premium de nos produits. De cette manière, nous préservons non seulement le pouvoir d\'achat des propriétaires d\'animaux de compagnie, mais nous nous assurons également d\'offrir une excellente nutrition à leurs compagnons à quatre pattes.',
            futureContent: 'Nous avons de grandes ambitions pour l\'avenir. Nous envisageons d\'étendre notre présence sur le territoire français en établissant des partenariats avec des boutiques spécialisées et des professionnels de confiance. De plus, nous avons pour projet de développer des lignes de produits spécifiques pour répondre aux besoins particuliers de certaines races ou de certains besoins spécifiques.',
          },
        },
      },
    },
    lng: localStorage.getItem('language') || 'fr',
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
