import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import NewsletterForm from "../components/Route/NewsletterForm";
import Testimonials from "../components/Shop/Temoignages";
import Footer from "../components/Layout/Footer";
import { getAllCategories } from "../redux/actions/category";
import { getAllSousCategories } from "../redux/actions/sousCategory";
import Contact from "../components/Route/Contact";
const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllSousCategories());
  }, [dispatch]);

  return (
    <div>
        <Header activeHeading={1} />
        <Hero />
        <Categories />
        <BestDeals />
        <Testimonials />
        <NewsletterForm/>
        <Contact />
        <Footer />
    </div>
  )
}

export default HomePage