// src/components/Hero.js

import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`element relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
        <h2
          className={`text-[35px] leading-[1.2] 800px:text-[40px] text-[#FFFFFF] font-[600] capitalize`}
        >
          {t('hero.title')}
        </h2>
      </div>
    </div>
  );
};

export default Hero;
