import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import {useSelector } from "react-redux";
import DropDown from "./DropDown";
import { useTranslation } from "react-i18next";
const Navbar = ({ active }) => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const {sousCategories} = useSelector(state => state.sousCategories);
  const navigate = useNavigate();
  const handleMouseEnter = (index) => {
    setOpenDropdown(index);
  };
  const submitHandle = (title) => {
    navigate(`/products?category=${title}`);
  };
  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };

  return (
    <div className={`block 800px:${styles.noramlFlex}`}>
      <div className="flex">
        <Link
          to="/"
          className={`${
            active === 1 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
          } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer`}
        >
          {t("navbar.home")}
        </Link>
      </div>
      <div className="flex">
        <Link
          to="/about"
          className={`${
            active === 2 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
          } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer`}
        >
          {t("navbar.about")}
        </Link>
      </div>
      <div
        className={`${
          active === 3 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
        } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer relative`}
        onMouseEnter={() => handleMouseEnter(1)}
        onMouseLeave={handleMouseLeave}
      >
        <div onClick={() => submitHandle("Chien")}>{t("navbar.dog")}</div>
        {openDropdown === 1 && (
          <DropDown
            categoriesData={sousCategories}
            setDropDown={"Chien"}
          />
        )}
      </div>
      <div
        className={`${
          active === 4 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
        } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer relative`}
        onMouseEnter={() => handleMouseEnter(2)}
        onMouseLeave={handleMouseLeave}
      >
        <div onClick={() => submitHandle("Chat")}>{t("navbar.cat")}</div>
        {openDropdown === 2 && (
          <DropDown
            categoriesData={sousCategories}
            setDropDown={"Chat"}
          />
        )}
      </div>
      <div
        className={`${
          active === 5 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
        } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer relative`}
        onMouseEnter={() => handleMouseEnter(3)}
        onMouseLeave={handleMouseLeave}
        
      >
        {t("navbar.format20kg")}
        {openDropdown === 3 && (
          <DropDown
            categoriesData={[
              {
                title: t("navbar.dummy"),
                url: "/dummy",
              },
            ]}
            setDropDown={t("navbar.format20kg")}
          />
        )}
      </div>
      <div
        className={`${
          active === 6 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
        } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer relative`}
      >
        {t("navbar.advantages")}
      </div>
      <Link
        to="/contact"
        className={`${
          active === 7 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
        } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer`}
      >
        {t("navbar.contact")}
      </Link>
      <Link
        to="/faq"
        className={`${
          active === 8 ? "text-[#ED1B24]" : "text-black1 800px:text-[#fff]"
        } pb-[30px] 800px:pb-0 font-[500] px-6 cursor-pointer`}
      >
        {t("navbar.faq")}
      </Link>
    </div>
  );
};

export default Navbar;
