import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footermenuLinks,
} from "../../static/data";
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#000] text-white">
      <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <img
            src="/logo.jpeg"
            alt=""
          />
          <br />
          <div className="flex items-center mt-[15px]">
            <AiFillFacebook size={25} className="cursor-pointer" />
            <AiOutlineTwitter
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillYoutube
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
          </div>
        </ul>
        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">{t('footer.enterprise')}</h1>
          {footerProductLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">{t('footer.category')}</h1>
          <li>
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.dog")}
              </Link>
            </li>
            <li>
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.cat")}
              </Link>
            </li>
        </ul>
        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">{t('footer.menu')}</h1>
            <li >
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.home")}
              </Link>
            </li>
            <li >
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.about")}
              </Link>
            </li>
            <li >
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.produit")}
              </Link>
            </li>
            <li >
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.contact")}
              </Link>
            </li>
            <li >
              <Link
                className="text-white hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={""}
              >
                {t("navbar.faq")}
              </Link>
            </li>
        </ul>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-gray-400 text-sm pb-8"
      >
        <span></span>
        <span></span>
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
