import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header activeHeading={2} />
      <br />
      <br />
      <div className="container mt-5">
        <h1>{t('about.title')}</h1>
        <br />
        <br />
        <Tabs id="about-tabs" className="mb-3">
          <Tab eventKey="engagement" title={t('about.engagement')}>
            <p>{t('about.engagementContent')}</p>
          </Tab>
          <Tab eventKey="partnership" title={t('about.partnership')}>
            <p>{t('about.partnershipContent')}</p>
          </Tab>
          <Tab eventKey="nutrition" title={t('about.nutrition')}>
            <p>{t('about.nutritionContent')}</p>
          </Tab>
          <Tab eventKey="future" title={t('about.future')}>
            <p>{t('about.futureContent')}</p>
          </Tab>
        </Tabs>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default AboutPage;
