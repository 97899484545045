import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  sousCategories: [], // Initial state for sousCategories
  success: false,
  error: null,
};

export const sousCategoryReducer = createReducer(initialState, {
  // Create SousCategory actions
  SOUS_CATEGORY_CREATE_REQUEST: (state) => {
    state.isLoading = true;
  },
  SOUS_CATEGORY_CREATE_SUCCESS: (state, action) => {
    state.isLoading = false;
    state.sousCategories.push(action.payload); // Assuming you want to add the created sousCategory to the list
    state.success = true;
  },
  SOUS_CATEGORY_CREATE_FAIL: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // Get all SousCategories actions
  GET_ALL_SOUS_CATEGORIES_REQUEST: (state) => {
    state.isLoading = true;
  },
  GET_ALL_SOUS_CATEGORIES_SUCCESS: (state, action) => {
    state.isLoading = false;
    state.sousCategories = action.payload;
  },
  GET_ALL_SOUS_CATEGORIES_FAILED: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Clear errors action (if needed)
  clearErrors: (state) => {
    state.error = null;
  },
});
