import React from "react";
import styles from "./Testimonials.module.css"; // Assuming you create a CSS module for your styles
import Marquee from "react-fast-marquee";
import styles1 from "../../styles/styles";
import { useTranslation } from "react-i18next";
const Testimonials = () => {
  const testimonialsData = [
    {
      id: 1,
      author: 'John Doe',
      text: 'I had a great experience with this company. Their products are fantastic!',
    },
    {
      id: 2,
      author: 'Jane Smith',
      text: 'Excellent customer service and high-quality products. Highly recommended!',
    },
    {
      id: 3,
      author: 'John Doe',
      text: 'I had a great experience with this company. Their products are fantastic!',
    },
    {
      id: 4,
      author: 'Jane Smith',
      text: 'Excellent customer service and high-quality products. Highly recommended!',
    },
    {
      id: 5,
      author: 'John Doe',
      text: 'I had a great experience with this company. Their products are fantastic!',
    },
    {
      id: 6,
      author: 'Jane Smith',
      text: 'Excellent customer service and high-quality products. Highly recommended!',
    },
    {
      id: 7,
      author: 'John Doe',
      text: 'I had a great experience with this company. Their products are fantastic!',
    },
    {
      id: 8,
      author: 'Jane Smith',
      text: 'Excellent customer service and high-quality products. Highly recommended!',
    },
  ];
  const { t } = useTranslation();

  return (
    <div>
    <br/>
    <br/>
      <div className={`${styles1.section}`}>
        <div className={`${styles1.heading}`}>
          <h1>{t("tem")}</h1>
        </div>
    <div className={styles.testimonialsContainer}>
      <Marquee>
        {testimonialsData.map((testimonial) => (
          <div className={styles.testimonialCard} key={testimonial.id}>
          <div className="myCarousel">
          <img src="/logo192.png" className="myCarouselImg" />
          <h3 className={styles.authorName}>{testimonial.author}</h3>
            <p className={styles.testimonialText}>{testimonial.text}</p>
          </div>
          </div>
        ))}
      </Marquee>
    </div>
    </div>
    <br/>
    <br/>
    </div>
  );
};

export default Testimonials;