import styles from "../../styles/styles";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next';
const Contact = () => {
  const { t } = useTranslation();
  const imageSrc =
    'contact.jpg';

  const containerStyles = {
    position: 'relative',
    width: '100%',
  };

  const listStyles = {
    listStyle: 'none',
    paddingLeft: 0,
  };

  const listItemStyles = {
    marginBottom: '10px',
  };
  return (
    <div className={`${styles.section}`}>
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
      {t('contact.title')}
      </h2>
    </div>
    <div className="contact-form">
      <div className="row">
        <form className="space-y-6 col-sm" style={{ width: '100%' }}>
          <div className="md:flex space-x-4">
            <div className="md:w-1/2">
              <label
                htmlFor="nom"
                className="block text-sm font-medium text-gray-700"
              >
                {t('contact.nom')}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="nom"
                  autoComplete="nom"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="md:w-1/2">
              <label
                htmlFor="prenom"
                className="block text-sm font-medium text-gray-700"
              >
                {t('contact.prenom')}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="prenom"
                  autoComplete="prenom"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t('contact.email')}
            </label>
            <div className="mt-1">
              <input
                type="email"
                name="email"
                autoComplete="email"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700"
            >
              {t('contact.message')}
            </label>
            <div className="mt-1">
              <textarea
                rows="4"
                name="message"
                autoComplete="message"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              {t('contact.envoyer')}
            </button>
          </div>
        </form>
        <div className="col-sm" style={containerStyles}>
          <img src={imageSrc} alt="Your Alt Text" className="imageStyles" />
          <div className="textStyles">
            <ul style={listStyles}>
              <li style={listItemStyles}>
                <div className="row">
                  <div className="col">
                    <img src="phone.png" width={'80px'} />
                  </div>
                  <div className="col-10">
                    <p className="text-base font-semibold">0751576909</p>
                    <span className="font-helvetica text-sm mt-1 text-sm text-neutral-dark-lighten-50 lg:text-white lg:text-base lg:mt-0">
                    {t('contact.temps')}
                    </span>
                  </div>
                </div>
              </li>
              <li style={listItemStyles}>
                <div className="row">
                  <div className="col">
                    <img src="location.jpg" width={'80px'} />
                  </div>
                  <div className="col-10">
                    <p className="text-base font-semibold">173 RUE DE LYON, 13015 MARSEILLE 15ème Arrondissement</p>
                  </div>
                </div>
              </li>
              <li style={listItemStyles}>
                <div className="row">
                  <div className="col">
                    <img src="email.png" width={'80px'} />
                  </div>
                  <div className="col-10">
                    <p className="text-base font-semibold">contact@lafyrme.com</p>
                  </div>
                </div>
              </li>
              <li style={listItemStyles}>
                <div className="row">
                  <div className="col">
                    <img src="shop.png" width={'80px'} />
                  </div>
                  <div className="col-10">
                    <p className="text-base font-semibold">{t('contact.info1')}</p>
                    <span className="font-helvetica text-sm mt-1 text-sm text-neutral-dark-lighten-50 lg:text-white lg:text-base lg:mt-0">
                    {t('contact.info2')} lafyrme.com
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </div>
  );
};

export default Contact;