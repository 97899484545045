// MyComponent.js
import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import { AiFillFacebook, AiFillInstagram, AiFillYoutube, AiOutlineTwitter } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

const MyComponent = () => {
  const { t } = useTranslation();

  const iconStyle = {
    width: '30px',
  };

  return (
    <div className='w-full py-16 text-white bg-[#000300] px-4 flex justify-center items-center'>
      <div className='max-w-[1240px] grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4'>
          <img src='news.jpg' width={'50%'} alt='News' />
        </div>
        <div className='my-4 flex flex-col items-center'>
          <div className='flex flex-col sm:flex-row items-center justify-center w-full mb-6'>
            <input
              className='p-3 w-full rounded-md text-black mb-2 sm:mb-0'
              type='email'
              placeholder={t('NewsletterForm.emailPlaceholder')}
            />
            <button className='bg-[#105FAE] text-black1 rounded-md font-medium w-[200px] ml-0 sm:ml-4 px-6 py-3'>
                {t('NewsletterForm.subscribeButton')}
            </button>
          </div>
          <p className='text-center'>
            <div className="flex items-center mt-[15px]">
              {t('NewsletterForm.followUs')}
              <AiFillFacebook size={25} className="cursor-pointer" />
              <AiOutlineTwitter
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
              />
              <AiFillInstagram
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
              />
              <AiFillYoutube
                size={25}
                style={{ marginLeft: "15px", cursor: "pointer" }}
              />
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default MyComponent;
