import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Contact from "../components/Route/Contact";
const ContactPage = () => {
  return (
      <div>
      <Header activeHeading={7} />
      <br />
      <br />
      <Contact />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default ContactPage;
