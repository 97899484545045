import { React, useState,useEffect } from "react";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
const Singup = () => {
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [societe, setSociete] = useState("");
  const [prenom, setPrenom] = useState("");
  const [num, setNum] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ nom,societe,prenom,num, email, avatar })
    axios
      .post(`${server}/user/create-user`, { nom,societe,prenom,num, email, avatar })
      .then((res) => {
        toast.success(res.data.message);
        setSociete("");
        setNom("");
        setPrenom("");
        setNum("");
        setEmail("");
        setAvatar();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const { i18n,t } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }
  return (
    <div>
      <div className="text-right mb-4 mr-4" style={{marginTop: "20px"}}>
        <select
          className="border-none cursor-pointer"
          onChange={(e) => changeLanguage(e.target.value)}
          value={i18n.language}
          style={{ backgroundColor: '#105FAE', color: 'white' }}
        >
          <option value="fr">
            <img src="/fr.png" alt="Flag of France" /> FR
          </option>
          <option value="en">
            <img src="/en.png" alt="Flag of England" /> EN
          </option>
        </select>
      </div>
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {t('signup.title')}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-6" onSubmit={handleSubmit}>
  <div>
    <label
      htmlFor="nom"
      className="block text-sm font-medium text-gray-700"
    >
      {t('signup.nom')}
    </label>
    <div className="mt-1">
      <input
        type="text"
        name="nom"
        autoComplete="name"
        required
        value={nom}
        onChange={(e) => setNom(e.target.value)}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  </div>
  <div>
    <label
      htmlFor="prenom"
      className="block text-sm font-medium text-gray-700"
    >
      {t('signup.prenom')}
    </label>
    <div className="mt-1">
      <input
        type="text"
        name="prenom"
        autoComplete="name"
        required
        value={prenom}
        onChange={(e) => setPrenom(e.target.value)}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  </div>
  <div>
    <label
      htmlFor="societe"
      className="block text-sm font-medium text-gray-700"
    >
      {t('signup.societe')}
    </label>
    <div className="mt-1">
      <input
        type="text"
        name="societe"
        autoComplete="name"
        required
        value={societe}
        onChange={(e) => setSociete(e.target.value)}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  </div>
  <div>
    <label
      htmlFor="num"
      className="block text-sm font-medium text-gray-700"
    >
      {t('signup.num')}
    </label>
    <div className="mt-1">
      <input
        type="number"
        name="num"
        autoComplete="name"
        required
        value={num}
        onChange={(e) => setNum(e.target.value)}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  </div>
  <div>
    <label
      htmlFor="email"
      className="block text-sm font-medium text-gray-700"
    >
      {t('signup.email')}
    </label>
    <div className="mt-1">
      <input
        type="email"
        name="email"
        autoComplete="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  </div>
  <div>
    <label
      htmlFor="avatar"
      className="block text-sm font-medium text-gray-700"
    ></label>
    <div className="mt-2 flex items-center">
      <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
        {avatar ? (
          <img
            src={avatar}
            alt="avatar"
            className="h-full w-full object-cover rounded-full"
          />
        ) : (
          <RxAvatar className="h-8 w-8" />
        )}
      </span>
      <label
        htmlFor="file-input"
        className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        <span>{t('signup.addPhoto')}</span>
        <input
          type="file"
          name="avatar"
          id="file-input"
          accept=".jpg,.jpeg,.png"
          onChange={handleFileInputChange}
          className="sr-only"
        />
      </label>
    </div>
  </div>
  <div>
    <button
      type="submit"
      className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
    >
      {t('signup.submitBtn')}
    </button>
  </div>
  <div className={`${styles.noramlFlex} w-full`}>
    <h4>{t('signup.alreadyHaveAccount')}</h4>
    <Link to="/login" className="text-blue-600 pl-2">
      {t('signup.signIn')}
    </Link>
  </div>
</form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Singup;
