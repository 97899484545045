import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../../styles/styles";
import { useTranslation } from "react-i18next";
const Categories = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSubmit = (title) => {
    navigate(`/products?category=${title}`);
  };
  return (
    <>
      <div className={`${styles.section} hidden sm:block`}>
      <div className={`branding my-12 flex flex-wrap justify-between w-full shadow-sm bg-white p-5 rounded-md`}>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-start" >
      <div className="row">
        <div className="col-sm text-center">
          <img src="/respect.png" alt="respect" width="130px" className="mx-auto" />
          <h4>{t("Categories.r1")}</h4>
          <p style={{ fontSize: "12px" }}>{t("Categories.r2")}</p>
        </div>
      </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-start" >
        <div className="col-sm text-center">
          <img src="/commande.png" alt="commande" width="130px" className="mx-auto" />
          <h4>{t("Categories.c1")}</h4>
          <p style={{ fontSize: "12px" }}>{t("Categories.c2")}</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-start" >
      <div className="col-sm text-center">
          <img src="/paiment.png" alt="paiment" width="130px" className="mx-auto" />
          <h4>{t("Categories.p1")}</h4>
          <p style={{ fontSize: "12px" }}>{t("Categories.p2")}</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-start" >
      <div className="col-sm text-center">
          <img src="/tarif.png" alt="tarif" width="100px" className="mx-auto" />
          <h4>{t("Categories.t1")}</h4>
          <p style={{ fontSize: "12px" }}>{t("Categories.t2")}</p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-start" >
      <div className="col-sm text-center">
          <img src="/f.png" alt="format" width="100px" className="mx-auto" />
          <h4>{t("Categories.f1")}</h4>
          <p style={{ fontSize: "12px" }}>{t("Categories.f2")}</p>
        </div>
      </div>
      </div>
      </div>

      <div
        className={`${styles.section} bg-white p-6 rounded-lg mb-12`}
        id="categories"
      >
        <div className="grid grid-cols-1 gap-[5px] md:grid-cols-2 md:gap-[10px] lg:gap-[20px]">
        <div
          className={`w-full h-[100px] flex items-center justify-content-evenly cursor-pointer overflow-hidden justify-center`}
          onClick={() => handleSubmit("chat")}
        >
          <h5 className={`text-[18px] leading-[1.3]`}>{t("navbar.cat")}</h5>
          <img src="/chat.jpg" className="w-[120px] object-cover" alt="" />
        </div>
        <div
          className={`w-full h-[100px] flex items-center justify-content-evenly cursor-pointer overflow-hidden `}
          onClick={() => handleSubmit("chien")}
        >
          <h5 className={`text-[18px] leading-[1.3]`}>{t("navbar.dog")}</h5>
          <img src="/chien.jpg" className="w-[120px] object-cover" alt="" />
        </div>
      </div>
      </div>
    </>
  );
};

export default Categories;
