import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const sousCategoryData = searchParams.get("sousCategory");
  const {allProducts,isLoading} = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState([]);

  useEffect(() => {
    if (sousCategoryData != null) {
      const d =
      allProducts && allProducts.filter((i)=>{
        return i.sousCategory._id === sousCategoryData
      });
      setData(d);
    } else {
      const d =
      allProducts && allProducts.filter((i) => i.cat === categoryData);
      setData(d);
    }
    if(categoryData === "Chien")
      setIndex(3)
    else
      setIndex(4)

    //    window.scrollTo(0,0);
  }, [allProducts,categoryData,sousCategoryData,index]);

  return (
  <>
  {
    isLoading ? (
      <Loader />
    ) : (
      <div>
      <Header activeHeading={index} />
      <br />
      <br />
      <div className={`${styles.section}`}>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {data && data.map((i, index) => <ProductCard data={i} key={index} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No products Found!
          </h1>
        ) : null}
      </div>
      <Footer />
    </div>
    )
  }
  </>
  );
};

export default ProductsPage;
