import React, { useEffect, useState } from "react";
import { getAllSousCategories } from "../redux/actions/sousCategory";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from '../components/Layout/AdminHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import ProductForm  from "../components/Admin/AddProduct";

const AdminDashboardAddProduct = () => {
  const dispatch = useDispatch();
  const sousCategories = useSelector(state => state.sousCategories);
  useEffect(() => {
    dispatch(getAllSousCategories());
  }, [dispatch]);
  return (
    <div>
    <AdminHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={5} />
        </div>
        <ProductForm sousCategories={sousCategories.sousCategories}  />
      </div>
    </div>
  </div>
  )
}

export default AdminDashboardAddProduct;