import React, { useEffect, useState } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);
  const [key, setKey] = useState('Composition');
  const totalReviewsLength =
  products &&
  products.reduce((acc, product) => acc + product.rating.length, 0);

  const totalRatings =
  products &&
  products.reduce(
      (acc, product) =>
        acc + product.rating,
      0
    );

  const avg =  totalRatings / totalReviewsLength || 0;
  const elements = data.composants.split(', ');
  const elementsAdd = data.additifsFR.split(', ');
  const tableStyle = {
    border: '1px solid #000',
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    border: '1px solid #000',
    padding: '8px',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid #000',
    padding: '8px',
    textAlign: 'left',
  };
  return (
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data && data.displayImage}`}
                  alt=""
                  className="w-[80%]"
                />
                <div className="w-full flex">
                  {data &&
                    data.images.map((i, index) => (
                      <div
                        className={`${
                          select === 0 ? "border" : "null"
                        } cursor-pointer`}
                      >
                        <img
                          src={`${i?.url}`}
                          alt=""
                          className="h-[200px] overflow-hidden mr-3 mt-3"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div>
              <div className="w-full 800px:w-[50%] pt-5">
                <h1 className={`${styles.productTitle}`}>{data.nameFR}</h1>
                {isAuthenticated ? (<div className="flex pt-3">
                  <h1 >
                    {data.price} €
                  </h1>
                </div>) : (null)}
              </div>
            </div>
            <br/>
            <br/>
            <Tabs
        id="about-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="Composition" title="Composition">
          <p>
          {data.descriptionFR}
          </p>
        </Tab>
        <Tab eventKey="Composants analytiques" title="Composants analytiques">
        <table style={tableStyle}>
      <tbody>
        {elements.map((element, index) => {
          // Use regex to split the element at the first number
          const [text, number] = element.split(/(\d+)/);
          const numberIndex = element.indexOf(number);

          const textBeforeNumber = element.substring(0, numberIndex).trim();
          const textAfterNumber = element.substring(numberIndex).trim();

          return (
            <tr key={index}>
              <td style={thStyle}><strong>{textBeforeNumber.toUpperCase()}</strong></td>
              <td style={tdStyle}>{textAfterNumber}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
        </Tab>
        <Tab eventKey="Additifs nutritionnels" title="Additifs nutritionnels">
          <p>
          <p >
        {elementsAdd.map((element, index) => {
          const parts = element.split(':');
          const text = parts.slice(0, -1).join(':').toUpperCase();
          const number = parts[parts.length - 1];
          return (
            <div>
              <p ><strong>{text}</strong></p>
              <p >{number}</p>
              </div>
          );
        })}
    </p>
          </p>
        </Tab>
      </Tabs>
          </div>
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetails;
