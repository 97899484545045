import React, { useState } from "react";
import {
  AiOutlineEye,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const ProductCard = ({ data,isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const [click, setClick] = useState(false);
  const [lang, setLang] = useState('fr');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const language = localStorage.getItem('language');
    console.log('Current language:', language);
    setLang(language);
    if (wishlist && wishlist.find((i) => i._id === data.id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist, data.id, lang]);

  useEffect(() => {
    const handleLanguageChange = () => {
      const language = localStorage.getItem('language');
      console.log('Language changed to:', language);
      setLang(language);
    };
    window.addEventListener('storage', handleLanguageChange);
  }, []);
  console.log(data)
  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="w-full h-[370px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer">
      <Link to={`/product/${data._id}`}>
        <div className="flex justify-end"></div>
          <img
            src={`${data.displayImage}`}
            alt=""
            className="w-full h-[170px] object-contain"
          />
          <h4 className="pb-3 font-[500]">
            {localStorage.getItem('language') === 'fr' ?(data.nameFR.length > 40 ? data.nameFR.slice(0, 40) + "..." : data.nameFR):(data.nameEN.length > 40 ? data.nameEN.slice(0, 40) + "..." : data.nameEN)}
          </h4>

          {user !== undefined ? (
  <div className="py-2 flex items-center justify-between">
    <div className="flex">
      <h5 className={`${styles.price}`}>
        à partir de {data.price === 0 ? data.price : data.price} €
      </h5>
      <h4 className={`${styles.price}`}>
        {data.price ? data.price + " €" : null}
      </h4>
    </div>
  </div>
) : null}

        {/* side options */}
        <div>
          {user !== undefined ? (
  <AiOutlineShoppingCart
  size={25}
  className="cursor-pointer absolute right-2 top-24"
  onClick={() => addToCartHandler(data.id)}
  color="#444"
  title="Add to cart"
/>
) : null}
          
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
        </Link>
      </div>
    </>
  );
};

export default ProductCard;
